import axios from "axios";

import { getAccessToken, clearTokens } from "../storage";

export const $host = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

$host.interceptors.request.use((request) => {
  const accessToken = getAccessToken();

  if (accessToken) {
    request.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return request;
});

$host.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      clearTokens();
      window.location.replace("/sign-in");
    }
    return Promise.reject(error)
  }
);
