import { createPortal } from "react-dom";
import DateTime from '../datetime/DateTime'
import { ReactComponent as Close } from "../../assets/close.svg";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { ReactComponent as Download } from "../../assets/download.svg";

import styles from "./lightbox.module.scss";

export default function Lightbox({ image, onClose }) {
  const content = (
    <div className={styles.lightbox} onClick={onClose}>
      <div className={styles.lightboxContent}>
        <ul className={styles.lightboxTools}>
          <li><button><Delete /></button></li>
          <li><button><Download /></button></li>
          <li><button onClick={onClose}><Close /></button></li>
        </ul>
        <div className={styles.lightboxImage}><img src={image.direct_link} alt={image.name} /></div>
        <div className={styles.lightboxTitle}>
          <h3>{image.name}</h3>
          <span><DateTime value={image.created_at} /></span>
        </div>
      </div>
    </div>
  );

  return createPortal(content, document.querySelector("#root"));
}
