import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { PulpApi } from "../../../../services/api";
import { Dropdown } from "../../../../components";
import { useSessionId } from '../../../EditorSessionProvider';

import styles from "./pulp_work_item.module.scss";

export default function Draft({
  item,
  workFlowConfig,
  pulpId,
  blockId,
  onWorkItemCreated,
}) {
  const sessionId = useSessionId();

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      workItemType: workFlowConfig.default_work_item_type,
      content: item.content,
    },
  });

  const createWorkItemMutation = useMutation({
    mutationFn: PulpApi.CreateWorkItem,
    onSuccess: ({ data }) => {
      onWorkItemCreated({ ...data, draft: false });
    },
  });

  const createWorkItem = (data) => {
    createWorkItemMutation.mutate({
      ...data,
      blockId,
      pulpId,
      sessionId,
      workItemId: item.id,
    });
  }

  return (
    <form onSubmit={handleSubmit(createWorkItem)} className="form-box">
      <div className="form-box-title">Create work item</div>
      <div className="form-box-row">
        <label>Name</label>
        <input
          className="form-box-input"
          { ...register('content', { required: true }) }
        />
      </div>
      <div className="form-box-row">
        <label>Name</label>
        <Dropdown
          type={"select"}
          value={workFlowConfig.default_work_item_type}
          selectProps={register("workItemType")}
          options={workFlowConfig.work_item_types}
          setValue={setValue}
          headerTemplate={({ id, option }) => (
            <div className={styles.item}>
              <img
                style={{ width: "15px" }}
                src={option.icon}
                alt={option.name}
              />
              {option.name}
            </div>
          )}
          optionTemplate={({ id, option }) => (
            <div className={styles.item}>
              <img
                style={{ width: "15px" }}
                src={option.icon}
                alt={option.name}
              />
              {option.name}
            </div>
          )}
        />
      </div>
      <div className="form-box-button">
        <button type="submit" className="button button-primary">
          Create work item
        </button>
      </div>
    </form>
  );
}
