export const features = [
  {
    id: 'feature_1',
    image: 'work-item.png',
    title: 'Work items',
    content: 'Use work items to describe the work to be done, assign work, track status, and coordinate efforts within your team.'
  },
  {
    id: 'feature_2',
    image: 'root-name.png',
    title: 'Infinite nesting',
    content: 'Develop deep ideas naturally without losing context.'
  },
  {
    id: 'feature_3',
    image: 'simple-share.png',
    title: 'Simple sharing permissions',
    content: 'Several options to share items quickly and securely.'
  },
  {
    id: 'feature_4',
    image: 'add-file.png',
    title: 'Files + images',
    content: 'Add unlimited files and images in-line. Simply drag-and-drop.'
  },
  {
    id: 'feature_5',
    image: 'search.png',
    title: 'Global search',
    content: 'Instantly search through all your documents, no more lost files.'
  },
  {
    id: 'feature_6',
    image: 'next-one.png',
    title: 'Kanban view',
    content: 'Turn lists into super-powered kanban boards'
  }
];

export const examples = [
  {
    id: 'example_1',
    title: 'Personal notes',
    image: 'personal.png',
    content: 'Quickly capture notes, ideas, and other important pieces of information',
    button: {
      title: 'Try now',
      url: '#'
    }
  },
  {
    id: 'example_2',
    title: 'Project management',
    image: 'personal2.png',
    content: 'Manage complex projects with tons of moving parts from one simple, organized space',
    button: {
      title: 'Try now',
      url: '#'
    }
  }
];

export const shortcuts = [
  {
    id: 'shortcut_1',
    title: 'Branches',
    items: [
      {
        id: 'add-branch',
        name: 'Add new branch bellow',
        commands: ["Enter"]
      },
      {
        id: 'add-block',
        name: 'Add block to branch',
        commands: ["Shift", "Enter"]
      },
      {
        id: 'move-b-up',
        name: 'Move branch up',
        commands: ["Shift", "command", "Arrow Up"]
      },
      {
        id: 'move-b-down',
        name: 'Move branch down',
        commands: ["Shift", "command", "Arrow Down"]
      },

      {
        id: "indent-right",
        name: "Indent branch right",
        commands: ["Tab"]
      },
      {
        id: "indent-left",
        name: "Indent branch left",
        commands: ["Shift", "Tab"]
      },

    ]
  },
  {
    id: 'shortcut_2',
    title: 'Blocks',
    items: [
      {
        id: 'add-block-below',
        name: 'Add block bellow',
        commands: ["Shift", "Enter"]
      },
      {
        id: 'outdent-block',
        name: 'Outdent block',
        commands: ["Shift", "Tab"]
      },
    ],
  }
];