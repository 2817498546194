import { useState } from 'react'
import { LightboxContext } from '../../contexts'

import Lightbox from './Lightbox';

export default function LightboxContextProvider({ children }) {
    const [image, setImage] = useState(null);

    return <LightboxContext.Provider value={{ open: setImage }}>
        { image ? <Lightbox image={image} onClose={() => setImage(null)} /> : null }
        {children}
    </LightboxContext.Provider>
}