export default function reducer(state, action) {
    const { type, payload } = action;

    if (type === 'SET_IS_SIDEBAR_OPEN') {
        return {
            ...state,
            isSidebarOpen: payload
        }
    }

    return state
}

const initialValue = {
    isSidebarOpen: true
};

export {
    initialValue,
}
