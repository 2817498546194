import { useMemo } from "react";
import { useAvatarFeedback } from "../../hooks";

const Avatar = ({ photo, title, size }) => {
  const avatarFeedback = useAvatarFeedback();

  const classes = useMemo(() => {
    return [
      "avatar",
      avatarFeedback.isLoading && "avatar-loading",
      avatarFeedback.isError && "avatar-error",
      size === "large" && "avatar-large",
    ]
      .filter(Boolean)
      .join(" ");
  }, [avatarFeedback.isLoading, avatarFeedback.isError, size]);

  return (
    <div className={classes}>
      {photo ? <img src={photo} alt={title} /> : null}
    </div>
  );
};

export default Avatar;
