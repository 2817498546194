import Draft from "./Draft";
import Clean from "./Clean";

export default function PulpReminder({
  item,
  pulpId,
  blockId,
  cursorPosition,
  onReminderCreated,
}) {
  return item.draft ? (
    <Draft
      {...{
        item,
        pulpId,
        blockId,
        cursorPosition,
        onReminderCreated,
      }}
    />
  ) : (
    <Clean
      {...{
        item,
      }}
    />
  );
}
