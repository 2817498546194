import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { useOutsideClick } from "../../../../hooks";
import { ActionItem } from '../../../../components';
import styles from "./styles.module.scss";


const tagSelectorPortal = document.querySelector('#root');

const TagSelectorMenu = ({
  position,
  close,
  onSelected,
  children: tagList,
}) => {
  const ref = useRef();
  useOutsideClick(ref, () => close());

  const [xy, setXY] = useState({ x: -999, y: -999 });

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        close();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const rect = ref.current.getBoundingClientRect();

    let x = position.x;
    let y = position.y;

    if (position.x + rect.width > window.innerWidth + window.scrollX) {
      x = position.x - rect.width;
    }

    if (position.y + rect.height > window.innerHeight + window.scrollY) {
      y = Math.max(55, position.y - rect.height)
    }

    setXY({ x, y })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const children = (
    <div
      ref={ref}
      className={styles.menuWrapper}
      style={{
        top: xy.y,
        left: xy.x,
      }}
    >
      <ul className={styles.menu}>
        {tagList.map((tag, key) => {
          if (tag?.items) {
            return <li key={key} className={styles.menuItem}>
              {tag.title && <span className={styles.menuItemTitle}>{tag.title}</span>}
              <ul>
                {tag.items.map((item, i) => <li key={i}>
                  <ActionItem
                    {...item}
                    title={item.label}
                    handleClick={() => {
                      onSelected({ tag: item.tag, payload: item.payload || {} });
                      close();
                    }}
                  />
                </li>)}
              </ul>
            </li>
          } else {
            return <li key={key} className={styles.menuItem}>
              <ActionItem
                {...tag}
                title={tag.label}
                handleClick={() => {
                  onSelected({ tag: tag.tag, payload: tag.payload || {} });
                  close();
                }}
              />
            </li>
          }
        })}
      </ul>
    </div>
  );

  return createPortal(children, tagSelectorPortal);
};

export default TagSelectorMenu;
