import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useProvideAuth } from "../hooks";


export default function LogoutView() {
    const navigate = useNavigate();
    const { logout: logoutUser } = useProvideAuth()

    useEffect(() => {
        logoutUser();
        navigate("/sign-in");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}