import { ReactComponent as IconComments } from "../../../../assets/comments.svg";
import styles from "./comments_badge.module.scss";

export default function CommentsBadge({ comments, comments_read, onClick }) {
  if (comments > 0) {
    const unread = comments - comments_read;
    return (
      <button className={styles.comments} onClick={onClick}>
        <IconComments /> {unread ? `${unread} unread` : ""}
      </button>
    );
  }
}
