import Draft from "./Draft";
import Clean from "./Clean";

export default function PulpWorkItem({
  item,
  workFlowConfig,
  pulpId,
  blockId,
  cursorPosition,
  onWorkItemCreated,
  onClick,
}) {
  return item.draft ? (
    <Draft
      {...{
        item,
        workFlowConfig,
        pulpId,
        blockId,
        cursorPosition,
        onWorkItemCreated,
      }}
    />
  ) : (
    <Clean
      {...{
        item,
        workFlowConfig,
        onClick,
      }}
    />
  );
}
