import { useReducer } from "react";
import { Outlet } from "react-router-dom";
import layoutReducer, { initialValue } from "./reducer";

import { ProvideAvatarFeedback } from "../components";

import SettingsSidebar from "./SettingsSidebar";
import Navbar from "./Navbar";

export default function SettingsLayout() {
  const [{ isSidebarOpen }, layoutDispatch] = useReducer(
    layoutReducer,
    initialValue
  );

  const handleOpenSidebar = () =>
    layoutDispatch({ type: "SET_IS_SIDEBAR_OPEN", payload: !isSidebarOpen });

  return (
    <div className={isSidebarOpen ? "container" : "container-full"}>
      <ProvideAvatarFeedback>
        <Navbar
          handleOpenSidebar={handleOpenSidebar}
          isSidebarOpen={isSidebarOpen}
        />
        <SettingsSidebar isSidebarOpen={isSidebarOpen} />
        <div className="content">
          <Outlet />
        </div>
      </ProvideAvatarFeedback>
    </div>
  );
}
