import { useState } from "react";
import { DateTime } from "../../../../components";
import TagSelectorMenu from "../tag-selector-menu/TagSelectorMenu";
import getOffset from "../../../../utils/getOffset";
import {ReactComponent as IconEdit} from "../../../../assets/edit.svg";
import {ReactComponent as IconDelete} from "../../../../assets/delete.svg";
import {ReactComponent as IconDots} from "../../../../assets/dots.svg";

import styles from "./work_item_modal_timeline_comment.module.scss";


const tagList = [
  {
    tag: 'edit',
    label: 'Edit',
    icon: <IconEdit />
  },
  {
    tag: "delete",
    label: "Delete",
    icon: <IconDelete />
  },
];

export default function WorkItemModalTimelineComment({ comment, className }) {
  const [tagSelectorPosition, setTagSelectorPosition] = useState(null);

  const handleDotsClick = (e) => setTagSelectorPosition(getOffset(e.target));

  return (
    <div className={`${styles.item} ${className ? className : ''}`}>
      <div className="avatar" />
      <div>
        <div>
          <strong>{comment.created_by.name}</strong>
          <small className={styles.date}><DateTime value={comment.created_at} /></small>
        </div>
        <p>{comment.content}</p>
      </div>
      <button className={styles.buttonDots} onClick={handleDotsClick}><IconDots /></button>
      {tagSelectorPosition && (
        <TagSelectorMenu
          position={tagSelectorPosition}
          close={() => setTagSelectorPosition(null)}
          // onSelected={handleSelected}
        >
          {tagList}
        </TagSelectorMenu>
      )}
    </div>
  );
}
