import { createPortal } from "react-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { CommentsApi } from "../../../../services/api";
import { useAuth } from "../../../../hooks";
import { commentsQueryKeys } from "../../../../constants/queryKeys";
import queryClient from "../../../../constants/queryClient";
import { ReactComponent as Close } from "../../../../assets/close.svg";
import WorkItemModalTimelineComment from "../work-item-modal-timeline-comment/WorkItemModalTimelineComment";
import { useSessionId } from '../../../EditorSessionProvider';

import styles from "./thread_aside.module.scss";

const threadPortal = document.querySelector("#root");

export default function ThreadAside({ item, onClose, onResetUnread }) {
  const sessionId = useSessionId();
  const { user } = useAuth();

  const commentsQuery = useQuery(
    commentsQueryKeys.for_(item.id, item.type),
    () => CommentsApi.For_(item.id, item.type),
    {
      onSuccess() {
        onResetUnread && onResetUnread();
      }
    }
  );

  const { register, handleSubmit, reset } = useForm();

  const postCommentMutation = useMutation({
    mutationFn: CommentsApi.PostComment,
    onSuccess: (data) => {
      queryClient.setQueryData(
        commentsQueryKeys.for_(item.id, item.type),
        (old) => [...old, data]
      );
      reset();
    },
  });

  const postComment = ({ comment }) => {
    postCommentMutation.mutate({
      object_id: item.id,
      content_type: item.type,
      content: comment,
      sessionId,
    });
  };


  let content;

  if (commentsQuery?.isLoading) {
    content = <div className={styles.wrap}><span className="loader" /></div>
  }

  if (commentsQuery?.isError) {
    content = <div className="alarm">Error fetching comments</div>;
  }

  if (commentsQuery?.data?.length && !commentsQuery?.isLoading) {
    content = (<div>
      {commentsQuery.data.map((comment) => <WorkItemModalTimelineComment key={comment.id} comment={comment} />)}
    </div>)
  } else {
    content = <div className={styles.wrap}>No comments yet</div>;
  }

  const children = (
    <div className={styles.thread}>
      <div className={styles.header}>
        <div className={styles.title} dangerouslySetInnerHTML={{__html: item.content}} />
        <button type="button" className={styles.close} onClick={() => onClose()}><Close/></button>
      </div>
      <div className={styles.content}>
        {content}
      </div>
      <form onSubmit={handleSubmit(postComment)} className={styles.form}>
        <div className="avatar">{user?.photo_link && <img src={user?.photo_link} alt={user?.name} />}</div>
        <textarea {...register("comment", { required: true })} />
        <button type="submit" className={styles.button}>Post Comment</button>
      </form>
    </div>
  );

  return createPortal(children, threadPortal);
}
