import { $host } from "./host";

export class AuthApi {
  static async Login({ email, password }) {
    const tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    const { data } = await $host.post(`/api/v1/auth/login/`, formData, {
      headers: {
        "content-type": "multipart/form-data"
      },
      params: {
        timeZone: tzName,
      }
    });
    return data;
  }

  static async Register({ email, name }) {
    await $host.post(`/api/v1/auth/register/`, { email, name });
  }

  static async SendOtp(email) {
    return await $host.post(`/api/v1/auth/send-login-token/`, { email });
  }

  static async ChangeEmail(obj) {
    return await $host.post(`/api/v1/auth/change-email/`, obj);
  }

  static async Me() {
    const { data } = await $host.get(`/api/v1/auth/me/`);
    return data;
  }
}
