import { useEffect } from "react";
import { useState, useRef } from "react";
import { useOutsideClick } from "../../hooks";
import styles from "./dropdown.module.scss";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Dropdown({
  classes,
  type,
  value,
  options,
  onClick,
  optionTemplate,
  headerTemplate,
  emptyTemplate,
  selectProps,
  setValue,
}) {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  useOutsideClick(ref, () => setOpen(false));

  const handleLinkClick = (id) => {
    setOpen(false);
    setLocalValue(id);
    onClick && onClick(id);
  };

  useEffect(() => {
    if (setValue && selectProps) setValue(selectProps.name, localValue)
  // eslint-disable-next-line
  }, [localValue])

  const classButton = [
    classes,
    styles.dropdownButton,
    open ? styles.dropdownButtonActive : '',
    type === 'select' ? styles.dropdownButtonSelect : '',
  ].join(' ');

  let select = null;
  if (selectProps) {
    select = (
      <select className={styles.hidden_select} {...selectProps}>
        {Object.keys(options).map((id) => (
          <option key={id} value={id}>
            {options[id].name}
          </option>
        ))}
      </select>
    );
  }

  return (
    <div className={styles.dropdown} ref={ref}>
      {select}
      <button
        className={classButton}
        type="button"
        onClick={() => setOpen(!open)}
      >
        {localValue
          ? headerTemplate
            ? headerTemplate({ id: localValue, option: options[localValue] })
            : options[localValue].name
          : emptyTemplate()}
      </button>
      {open && (
        <ul className={styles.dropdownMenu}>
          {Object.keys(options).map((id) => (
            <li key={id}>
              <button
                className={styles.dropdownItem}
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClick(id);
                }}
              >
                {optionTemplate
                  ? optionTemplate({ id, option: options[id] })
                  : options[id].name}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
