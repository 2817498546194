import React from "react";
import ReactDOM from "react-dom/client";

import { QueryClientProvider } from "@tanstack/react-query";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";
import { clarity } from "react-microsoft-clarity";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import queryClient from "./constants/queryClient";
import * as storage from "./services/storage";
import parseJWT from "./utils/parseJWT";

import "./styles/main.scss";

Sentry.init({
  dsn: "https://c40746391af146ad869cd1af24cfc7b7@o4504779872337920.ingest.sentry.io/4504779892064256",
  environment: process.env.NODE_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.3,
  debug: process.env.NODE_ENV  !==  'production',
  tunnel: (process.env.REACT_APP_BASE_URL || "") + '/api/v1/sentry/tunnel/',
});

mixpanel.init("e3deefce64151f1aacc41792b15b33ce", {
  debug: false,
});

ReactGA.initialize("G-4WY70JDB7N");

clarity.init("ga6gm6eg6e");

const token = storage.getAccessToken();
if (token) {
  const user = parseJWT(token);
  mixpanel.identify(user.sub);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
