import { useMutation } from "@tanstack/react-query";

import { PulpApi } from "../../../../services/api";
import { Modal } from "../../../../components";
import { ReactComponent as Delete } from "../../../../assets/delete.svg";
import styles from "./pulp_delete_modal.module.scss";

export default function PulpDeleteModal({ onClose, isOpen, id }) {
  const deleteRootMutation = useMutation({
    mutationFn: PulpApi.DeleteRoot,
    onSuccess() {
        onClose({ id });
    }
  });

  const onDelete = () => {
    deleteRootMutation.mutate({ id });
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title={"Delete root"}
      titleIcon={<Delete />}
      size={"small"}
    >
      <div className="text-center">
        <p>Are you sure?</p>
        <div className={styles.buttons}>
          <button onClick={onClose} className="button button-gray">Cancel</button>
          <button onClick={onDelete} className="button button-red">Delete</button>
        </div>
      </div>
    </Modal>
  );
}
