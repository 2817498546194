import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Logo } from "../assets/logo-small.svg";

import { useProvideAuth } from "../hooks";
import { AuthApi } from "../services/api";

export default function LoginView() {
  const { login: loginUser } = useProvideAuth();

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [showOtp, setShowOtp] = useState(false);

  const loginMutation = useMutation({
    mutationFn: AuthApi.Login,
    onSuccess: (data) => {
      loginUser(data.access_token)
      navigate("/");
    },
    onError: (data) => {
      setShowOtp(false);
      setToken('');
    },
  });

  const sentOtpMutation = useMutation({
    mutationFn: AuthApi.SendOtp,
    onSuccess: (data) => {
      setShowOtp(true)
    },
    onError: (data) => {
      setShowOtp(false);
      setToken('');
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();

    if (showOtp) {
      loginMutation.mutate({ email, password: token });
    } else {
      sentOtpMutation.mutate(email);
    }
  };

  return (
    <div className="login-page">
      <div className="logo"><Logo /></div>
      <form onSubmit={onSubmit} className="form">
        <h2>Sign In</h2>
        <p>Specify email address to<br/> get one-time password</p>

        { sentOtpMutation.error && <div className="alert">Cannot send one-time password</div> }
        { loginMutation.error && <div className="alert">Cannot login user</div> }

        <input
          type="email"
          className="input-user"
          id="floatingEmail"
          placeholder="name@example.com"
          value={email}
          onInput={(e) => setEmail(e.target.value)}
          required
        />

        {showOtp && (
          <>
            <p>Check your mailbox for the one-time password</p>
            <input
              type="text"
              minLength={8}
              maxLength={8}
              className="input-password"
              id="floatingOtp"
              placeholder="otp"
              value={token}
              onInput={(e) => setToken(e.target.value)}
              required
            />
          </>
        )}

        <button
          disabled={loginMutation.isLoading || sentOtpMutation.isLoading}
          className="button button-primary button-fullwidth"
          type="submit"
        >
          { (loginMutation.isLoading || sentOtpMutation.isLoading) ? 'Working...' : showOtp ? 'Sign in' : 'Get one-time password' }
        </button>

        <a href="/sign-up">
          Done have an account? Sign up
        </a>
      </form>
      <ul className="copy">
        <li>Kaban team © 2022</li>
        <li>info@kaban.app</li>
      </ul>
    </div>
  );
}
