import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Board } from "../../assets/view-kanban.svg";
import { ReactComponent as List } from "../../assets/view-list.svg";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { ReactComponent as Share } from "../../assets/share.svg";
import styles from "./toolbar.module.scss";
import Dropdown from "../dropdown/Dropdown";

import queryClient from "../../constants/queryClient";
import { pulpApiQueryKeys } from "../../constants/queryKeys";
import { useDeletePulp, useSharePulp } from "../../views/PulpView/hooks";

const items = {
  list: {
    name: 'Tree',
    icon: <List />,
    path: 'p'
  },
  board: {
    name: 'Board',
    icon: <Board />,
    path: 'b'
  }
};

export default function Toolbar({ urlKey, defaultView, isFullWidth }) {
  const history = useNavigate();

  const { key: selectedPulpKey } = useParams();

  const sharePulp = useSharePulp();

  const deletePulp = useDeletePulp(function(obj) {
    if (obj.id) {
      queryClient.setQueryData(pulpApiQueryKeys.roots(), (old) => old.filter(o => o.id !== obj.id));
      history('/')
    }
  });

  return <div className={isFullWidth ? styles.toolbarFullWidth : styles.toolbar}>
    <div>
      <Dropdown
        classes={styles.select}
        type={'select'}
        value={defaultView}
        options={items}
        optionTemplate={({ id, option }) => <div className={styles.item}>{option.icon}{option.name}</div>}
        headerTemplate={({ id, option }) => <div className={styles.item}>{option.icon}{option.name}</div>}
        onClick={id => {
          history(`/${items[id].path}/${urlKey}`);
        }}
      />
    </div>
    <div>
      <button className="button button-outline" onClick={() => sharePulp({ id: selectedPulpKey })}><Share /> Share root</button>
      <button className="button button-icon" onClick={() => deletePulp({ id: selectedPulpKey })}><Delete /></button>
    </div>
  </div>
}