import { createContext, useMemo, useContext } from "react";

import { newId } from "./PulpView/reducer/utils";

const EditorSessionIdContext = createContext();

export function useSessionId() {
  return useContext(EditorSessionIdContext);
}

export function clientState() {
  const now = new Date();
  const utc_now = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds()
  );
  return new Date(utc_now).toISOString();
}

export default function EditorSessionProvider({ children }) {
  const sessionId = useMemo(() => {
    return newId();
  }, []);

  return (
    <EditorSessionIdContext.Provider value={sessionId}>
      {children}
    </EditorSessionIdContext.Provider>
  );
}
