import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import useOutsideClick from '../../hooks/useOutsideClick';
import { ReactComponent as Close } from '../../assets/close.svg';
import styles from './modal.module.scss';

const modalPortal = document.querySelector('#root');

const Modal = ({ isOpen, onClose, title, titleIcon, children, size }) => {
  const modalRef = useRef(null);

  useOutsideClick(modalRef, () => onClose());

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }

    return () => document.body.style.overflow = 'visible'
  }, [isOpen]);

  const modalClasses = `${styles.modal}
                        ${size === 'small' ? styles.modalSmall : ''}
                        ${size === 'medium' ? styles.modalMedium : ''}`;

  return createPortal((
    <div className={isOpen ? styles.modalContainerActive : styles.modalContainer}>
      <span className={styles.mask} />
      <div className={styles.modalDialog}>
      <div className={modalClasses} ref={modalRef}>
        {title && <header className={styles.modalHeader}>
          <div className={styles.modalTitle}>
            {titleIcon && titleIcon}
            {title}
          </div>
          <button className={styles.modalClose} onClick={onClose}><Close /></button>
        </header>}
        <div className={styles.modalContent}>
          {children}
        </div>
      </div>
      </div>
    </div>
  ), modalPortal);
};

export default Modal;