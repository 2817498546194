import { useParams } from 'react-router-dom';
import styles from './pulp_work_item.module.scss';

export default function Clean({ item, workFlowConfig, onClick }) {
  const { key } = useParams();

  return (
    <div className={styles.item}>
      <img
        style={{ width: "15px" }}
        src={workFlowConfig.work_item_types[item.work_flow.type].icon}
        alt={item.work_flow.type}
      />
      <a
        href={`/w/${key}/${item.id}`}
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
      >
        {item.content}
      </a>
    </div>
  );
}
