
import DateTime from "../../../../components/datetime/DateTime";
import styles from "./work_item_modal_timeline_changelog.module.scss";

const CHANGE_TYPE_CREATED = 1;
const CHANGE_TYPE_FILE_ADDED = 2;
//const CHANGE_TYPE_DUE_SET = 3;
const CHANGE_TYPE_WT_STATUS_CHANGED = 4;
const CHANGE_TYPE_WT_ASSIGNEE_CHANGED = 5;

export default function WorkItemModalTimelineChangelog({ changelog }) {
  const { change_type, change_values, changed_at, changed_by } = changelog;

  let line = null;

  if (change_type === CHANGE_TYPE_CREATED) {
    line = <><span className={styles.name}>{changed_by.name}</span> created this work item.</>;
  } else if (change_type === CHANGE_TYPE_FILE_ADDED) {
    line = (
      <>
        <span className={styles.name}>{changed_by.name}</span> attached file <a href={change_values.file.direct_link}>{change_values.file.name}</a>.
      </>
    );
  } else if (change_type === CHANGE_TYPE_FILE_ADDED) {
    line = (
      <>
        <span className={styles.name}>{changed_by.name}</span> attached file <a href={change_values.file.direct_link}>{change_values.file.name}</a>.
      </>
    );
  } else if (change_type === CHANGE_TYPE_WT_STATUS_CHANGED) {
    line = (
        <>
          <span className={styles.name}>{changed_by.name}</span> changed status in {change_values.work_type.name} from "{change_values.from_status.name}" to "{change_values.to_status.name}".
        </>
    )
  } else if (change_type === CHANGE_TYPE_WT_ASSIGNEE_CHANGED) {
    line = (
      <>
        <span className={styles.name}>{changed_by.name}</span> changed assignee in {change_values.work_type.name} from "{change_values.from_member.name}" to "{change_values.to_member.name}".
      </>
  )
  }

  return (
    <div className={styles.item}>
      {line}
      <span className={styles.date}><DateTime value={changed_at} /></span>
    </div>
  );
}
