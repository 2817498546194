import { useReducer, useState, useContext, useEffect, useMemo } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useParams, useOutletContext } from "react-router-dom";

import { BoardApi, PulpApi, TeamApi } from "../../services/api";
import { boardKeys, pulpApiQueryKeys, teamApiQueryKeys } from "../../constants/queryKeys";

import { WorkItemModal } from "../PulpView/components";
import { renderFiles } from "../PulpView/PulpView";
import { PulpBreadcrumbsContext } from "../../contexts";

//import boardReducer, { initialValue as boardInitialValue } from "./reducer";
import pulpReducer, {
  initialValue as pulpInitialValue,
} from "../PulpView/reducer";
import { ReactComponent as Attach } from "../../assets/icon-clip.svg";
import { ReactComponent as Comments } from "../../assets/comments.svg";
import Toolbar from "../../components/toolbar/Toolbar";
import { useSessionId, clientState } from "../EditorSessionProvider";
import { useInterval, useAvatarFeedback } from "../../hooks";

import styles from "./board_view.module.scss";


export default function BoardView() {
  const avatarFeedback = useAvatarFeedback();
  const sessionId = useSessionId();
  const { key } = useParams();
  const { isSidebarOpen } = useOutletContext();

  const { setBreadcrumbs } = useContext(PulpBreadcrumbsContext);

  //const [board, boardDispatch] = useReducer(boardReducer, boardInitialValue);
  const [pulp, pulpDispatch] = useReducer(pulpReducer, pulpInitialValue);

  const board = useMemo(() => {
    const { items, workFlow } = pulp

    if (!workFlow.statuses) {
      return {
        columns: [],
      }
    }

    const columns = []
    const work_items = Object.keys(items).filter(key => items[key].type === 'work-item').map(key => items[key]);

    for(const [statusId, statusObj] of Object.entries(workFlow.statuses)) {
        let workItems = []

        for(const [workTypeId, workTypeObj] of Object.entries(workFlow.work_types)) {
            const wis = work_items
                .filter(wi => wi.work_flow.work_types[workTypeId]?.status_id === statusId)
                .map(wi => ({ 
                    workTypeId, 
                    statusId,  
                    workTypeName: workTypeObj.name, 
                    id: wi.id, 
                    typeId: wi.work_flow.type, 
                    memberId: wi.work_flow.work_types[workTypeId].member_id,
                    createdBy: wi.created_by,
            }));
            
            workItems.push(...wis)
        }

        columns.push({ id: statusId, name: statusObj.name, order: statusObj.order, items: workItems })
    }

    return {
        columns: columns.sort((a,b) => a.order - b.order),
        workFlow,
    }
  }, [pulp]);

  const [activeWorkItem, setActiveWorkItem] = useState(null);

  const membersQuery = useQuery(teamApiQueryKeys.members(key), () => TeamApi.Members(key));
  const membersCache = useMemo(() => {
    const map_ = {}

    if (membersQuery.data) {
      for(const member of membersQuery.data) {
        map_[member.id] = member
      }
    }

    return map_
  }, [ membersQuery.data ]);

  const { isLoading, isError } = useQuery(
    boardKeys.workItems(key),
    () => BoardApi.WorkItems(key, sessionId),
    {
      onSuccess: (data) => {
        pulpDispatch({ type: "INIT", payload: { data, key } });
      },
    }
  );

  const pullMutation = useMutation({
    mutationFn: BoardApi.Pull,
    onSuccess(data) {
      pulpDispatch({ type: "INIT_SUBTREE", payload: data });
      avatarFeedback.reset();
    }, 
    onError() {
      avatarFeedback.reset();
      avatarFeedback.indicateError();
    }, 
    onMutate() {
      avatarFeedback.indicateLoading();
    }
  });

  useInterval(() => {
    pullMutation.mutate({
      key,
      session_id: sessionId,
      client_state: clientState(),
    })
  }, 10000)

  const rootQuery = useQuery(pulpApiQueryKeys.root(key), () =>
    PulpApi.Root(key)
  );

  useEffect(() => {
    if (rootQuery.data) {
      setBreadcrumbs([
        {
          id: rootQuery.data.id,
          name: rootQuery.data.content,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootQuery.data]);

  if (isLoading) {
    return <p>Loading...</p>;
  } else if (isError) {
    return <div className="alarm">Error fetching board</div>;
  } else {
    const boardElements = board.columns.map((column) => {
      return (
        <div className={styles.column} key={column.id}>
          <div className={styles.head}>
            <span className={styles.title}>{column.name}</span>
            {column.items.length ? (
              <span className={styles.count}>{column.items.length}</span>
            ) : null}
          </div>
          {column.items.map((wi) => (
            <div className={styles.card} key={wi.id + wi.workTypeId}>
              <img
                src={board.workFlow.work_item_types[wi.typeId].icon}
                width={16}
                alt={board.workFlow.work_item_types[wi.typeId].name}
              />
              <h2>
                <a
                  className={styles.cartTitle}
                  href={`/w/${key}/${wi.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveWorkItem(pulp.items[wi.id]);
                  }}
                >
                  [{wi.workTypeName}] {pulp.items[wi.id].content}
                </a>
              </h2>
              <div className={styles.cardInfo}>
                <div className="avatar">
                  { membersCache[wi.memberId] ? (
                    <img
                      src={membersCache[wi.memberId].photo_link}
                      alt={membersCache[wi.memberId].name}
                    />
                  ) : null}
                </div>
                <ul className={styles.cardInfoList}>
                    { false ? <li><Comments />0</li> : null }
                    {pulp.files[pulp.items[wi.id].parent]?.length ? <li><Attach /></li> : null}
                </ul>
              </div>
            </div>
          ))}
        </div>
      );
    });

    return (
      <>
        <Toolbar urlKey={key} defaultView="board"  isFullWidth={!isSidebarOpen} />

        <div className={styles.board}>
          {boardElements}

          {activeWorkItem ? (
            <WorkItemModal
              root={key}
              isOpen={!!activeWorkItem}
              item={activeWorkItem}
              workFlowConfig={pulp.workFlow}
              onClose={() => setActiveWorkItem(null)}
              onWorkItemUpdated={(changset) => {
                pulpDispatch({
                  type: "EDIT_ITEM",
                  payload: {
                    id: activeWorkItem,
                    ...changset,
                  },
                });
              }}
              files={renderFiles(
                pulp,
                activeWorkItem.parent,
                pulp.items[activeWorkItem.parent].parent,
                pulpDispatch
              )}
            />
          ) : null}
        </div>
      </>
    );
  }
}
