import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { PulpApi } from "../../../../services/api";
import { ReactComponent as IconClock } from "../../../../assets/clock.svg";
import { Dropdown } from "../../../../components";
import alarmOptions from "../../../../constants/alarmOptions";
import { useSessionId } from '../../../EditorSessionProvider';


export default function Draft({ item, pulpId, blockId, onReminderCreated }) {
  const sessionId = useSessionId();

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      content: item.content,
    }
  });

  const createReminder = (data) => {
    createReminderMutation.mutate({
      content: data.content,
      blockId,
      pulpId,
      reminderId: item.id,
      date: data.date,
      alert_relative_value: data.relativeValue,
      alert_absolute_value: null,
      alert_is_relative: true,
      sessionId,
    });
  };

  const createReminderMutation = useMutation({
    mutationFn: PulpApi.CreateReminder,
    onSuccess: ({ data }) => {
      onReminderCreated({ ...data, draft: false });
    },
  });

  return (
    <form onSubmit={handleSubmit(createReminder)} className="form-box">
      <div className="form-box-title">
        <IconClock />
        Add reminder:{item.content}
      </div>
      <div className="form-box-row">
        <label htmlFor="reminderContent">Event name</label>
        <textarea
          id="reminderContent"
          rows="3"
          {...register("content", { required: true })}
        />
      </div>
      <div className="form-box-row form-box-row-grid">
        <div>
          <input
            className="reminder-input"
            type="datetime-local"
            id="reminderDate"
            {...register("date", { required: true })}
          />
        </div>
        <div>
          <Dropdown
            type={"select"}
            value={"0"}
            selectProps={register("relativeValue")}
            options={alarmOptions}
            setValue={setValue}
            headerTemplate={({id, option}) => {
              return <div className="reminder-item">{option.name}</div>
            }}
            emptyTemplate={() => <div className="reminder-item">Alarm before</div>}
          />
        </div>
      </div>
      <div className="form-box-button">
        <button type="submit" className="button button-primary">
          Set reminder
        </button>
      </div>
    </form>
  );
}
