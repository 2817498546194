import { useState } from 'react';
import Features from './components/Features';
//import Examples from './components/Examples';
import Shortcuts from './components/Shortcuts';

import styles from './home_page.module.scss';

const tabs = [
  {
    id: 'tab_1',
    title: 'Features',
    tabContent: <Features classes={styles} />
  },
  /*{
    id: 'tab_2',
    title: 'Examples',
    tabContent: <Examples classes={styles} />
  },*/
  {
    id: 'tab_3',
    title: 'Shortcuts',
    tabContent: <Shortcuts classes={styles} />
  },
];

export default function HomeView() {
  const [currentTab, setCurrentTab] = useState('tab_1');

  const handleTabClick = (e) => setCurrentTab(e.target.id);

  return <div className={styles.root}>
    <div className={styles.tabs}>
      {tabs.map(({ id, title }) =>
        <button key={id} id={id} disabled={currentTab === id} onClick={(handleTabClick)}>{title}</button>
      )}
    </div>
    <div className={styles.tabsContent}>
      {tabs.map(({ id, tabContent }) => currentTab === id && <div  key={id}>{tabContent}</div>)}
    </div>
  </div>
}
