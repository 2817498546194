import React, { useMemo, useState, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { CommentsApi, WorkItemsApi, TeamApi } from "../../../../services/api";
import { useAuth } from "../../../../hooks";
import {
  commentsQueryKeys,
  workItemsKeys,
  teamApiQueryKeys,
} from "../../../../constants/queryKeys";
import { InlineInput, InlineTextarea, Dropdown } from "../../../../components";
import WorkItemModalTimelineChangelog from "../work-item-modal-timeline-changelog/WorkItemModalTimelineChangelog";
import WorkItemModalTimelineComment from "../work-item-modal-timeline-comment/WorkItemModalTimelineComment";
import queryClient from "../../../../constants/queryClient";
import { Modal } from "../../../../components";
import { ReactComponent as IconCheck } from "../../../../assets/icon-check.svg";
import styles from "./work_item_modal.module.scss";
import { useSessionId } from '../../../EditorSessionProvider';


function pickTextColorBasedOnBgColorAdvanced(bgColor) {
  if (bgColor === '#fff' || bgColor === '#ffffff') {
    return '#000000'
  }

  if (bgColor === '#000' || bgColor === '#000000') {
    return '#ffffff'
  }

  const lightColor = '#ffffff';
  const darkColor = '#000000';
  var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
  return (L > 0.179) ? darkColor : lightColor;
}


export default function WorkItemModal({
  item,
  workFlowConfig,
  onWorkItemUpdated,
  onResetUnread,
  files,
  onClose,
  isOpen,
  root,
}) {
  const { user } = useAuth();
  const sessionId = useSessionId();

  const changelogQuery = useQuery(workItemsKeys.changelog(item.id), () =>
    WorkItemsApi.Changelog(item.id)
  );

  const updateWorkItemMutation = useMutation({
    mutationFn: WorkItemsApi.PartialUpdate,
    onSuccess: (data) => {
      onWorkItemUpdated(data);
      changelogQuery.refetch();
    },
  });

  const membersQuery = useQuery(teamApiQueryKeys.members(root), () =>
    TeamApi.Members(root)
  );

  const commentsQuery = useQuery(
    commentsQueryKeys.for_(item.id, "work-item"),
    () => CommentsApi.For_(item.id, "work-item"),
    {
      onSuccess() {
        onResetUnread && onResetUnread()
      }
    }
  );

  const [members, setMembers] = useState({
    unassigned: {
      name: "Unassigned",
    },
  });

  useEffect(() => {
    if (membersQuery.data) {
      const newAssignee = {
        unassigned: {
          name: "Unassigned",
        },
      };

      for (const member of membersQuery.data) {
        newAssignee[member.id] = {
          name: member.name,
          avatar: member.photo_link
        };
      }

      setMembers(newAssignee);
    }
  }, [membersQuery.data]);

  const { register, handleSubmit, reset } = useForm();

  const postCommentMutation = useMutation({
    mutationFn: CommentsApi.PostComment,
    onSuccess: (data) => {
      queryClient.setQueryData(
        commentsQueryKeys.for_(item.id, "work-item"),
        (old) => [...old, data]
      );
      reset();
    },
  });

  const postComment = ({ comment }) => {
    postCommentMutation.mutate({
      object_id: item.id,
      content_type: "work-item",
      content: comment,
      sessionId,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <InlineInput
          value={item.content}
          onChange={(content) =>
            updateWorkItemMutation.mutate({
              id: item.id,
              changeset: { content },
              sessionId,
            })
          }
        />
      }
    >
      {/*<div className={styles.reminder}>
        <div>
          <input
            className="reminder-input"
            type="datetime-local"
            id="reminderDate"
          />
        </div>
        <div>
          <Dropdown
            type={"select"}
            value={"none"}
            selectProps={register("relativeValue")}
            options={alarmOptions}
            // setValue={setValue}
            headerTemplate={({id, option}) => {
              if (id === "none") {
                return <div className="reminder-item">Alarm before</div>;
              } else {
                return <div className="reminder-item">{option.name}</div>;
              }
            }}
            emptyTemplate={() => <div className="reminder-item">Alarm before</div>}
          />
        </div>
          </div>*/}
      <div className={styles.table}>
        <div className={styles.tableHeadRow}>
          <div>Type of work</div>
          <div>Assignee</div>
          <div>Status</div>
        </div>
        {Object.keys(item.work_flow.work_types).map((workTypeId) => (
          <div className={styles.tableRow} key={workTypeId}>
            <div>
              <span className={styles.tableTitle}>
                <IconCheck />
                {workFlowConfig.work_types[workTypeId].name}
              </span>
            </div>
            <div>
              <Dropdown
                value={
                  item.work_flow.work_types[workTypeId].member_id ||
                  workFlowConfig.default_member_id ||
                  "unassigned"
                }
                options={members}
                optionTemplate={({ id, option }) => (
                  <div className="user">
                    <div className="avatar">{option.avatar && <img src={option.avatar} alt={option.name}/>}</div>
                    {option ? option.name : "Loading..."}
                  </div>
                )}
                headerTemplate={({ id, option }) => (
                  <div className="user">
                    <div className="avatar">{option?.avatar && <img src={option.avatar} alt={option.name}/>}</div>
                    {option ? option.name : "Loading..."}
                  </div>
                )}
                onClick={(newMemberId) => {
                  updateWorkItemMutation.mutate({
                    id: item.id,
                    changeset: {
                      work_type_member: {
                        work_type_id: workTypeId,
                        member_id: newMemberId,
                      },
                    },
                    sessionId,
                  });
                }}
              />
            </div>
            <div>
              <Dropdown
                value={item.work_flow.work_types[workTypeId].status_id}
                options={workFlowConfig.statuses}
                optionTemplate={({ id, option }) => (
                  <span className='badge' style={{backgroundColor: option.color, color: pickTextColorBasedOnBgColorAdvanced(option.color)}}>{option.name}</span>
                )}
                headerTemplate={({ id, option }) => (
                  <span className='badge' style={{backgroundColor: option.color, color: pickTextColorBasedOnBgColorAdvanced(option.color)}}>{option.name}</span>
                )}
                onClick={(newStatusId) => {
                  updateWorkItemMutation.mutate({
                    id: item.id,
                    sessionId,
                    changeset: {
                      work_type_status: {
                        work_type_id: workTypeId,
                        status_id: newStatusId,
                      },
                    },
                  });
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.block}>
        <h2>Description</h2>
        <InlineTextarea
          value={item.body}
          placeholder="Add more details here..."
          onChange={(body) =>
            updateWorkItemMutation.mutate({
              id: item.id,
              sessionId,
              changeset: { body },
            })
          }
        />
      </div>
      <div className={`${styles.block} ${styles.blockAttach}`}>
        <h2>Attachments</h2>
        {files}
      </div>

      <div className={styles.comments}>
        {changelogQuery.isLoading ? (
          <p>Loading</p>
        ) : changelogQuery.isError ? (
          <div className="notification notification-alarm">Error fetching changelog</div>
        ) : (
          changelogQuery.data.map((item) => {
            return (
              <WorkItemModalTimelineChangelog
                key={item.id}
                changelog={item}
              />
            );
          })
        )}
      </div>

      <div className={styles.comments}>
        {commentsQuery.isLoading ? (
          <p>Loading</p>
        ) : commentsQuery.isError ? (
          <div className="notification notification-alarm">Error fetching comments</div>
        ) : (
          commentsQuery.data.map((item) => {
            return (
              <WorkItemModalTimelineComment
                key={item.id}
                comment={item}
                className={styles.commentsLine}
              />
            );
          })
        )}
      </div>

      <form onSubmit={handleSubmit(postComment)} className={styles.formComment}>
        <div className="avatar">{user?.photo_link && <img src={user?.photo_link} alt={user?.name} />}</div>
        <textarea {...register("comment", { required: true })} />
        <div className={styles.formCommentButton}>
          <button type="submit" className="button button-primary">
            Comment
          </button>
        </div>
      </form>
    </Modal>
  );
}
