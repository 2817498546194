import { useRef, useMemo, useReducer } from "react";

import TagSelectorMenu from "../tag-selector-menu/TagSelectorMenu";
import MoveToSelectorMenuMenu from "../move-to-selector-menu/MoveToSelectorMenu";
import getOffset from "../../../../utils/getOffset";
import { ReactComponent as IconComments } from "../../../../assets/comments.svg";
import { ReactComponent as IconDots } from "../../../../assets/dots.svg";
import { ReactComponent as IconText } from "../../../../assets/text.svg";
import { ReactComponent as IconWork } from "../../../../assets/work.svg";
import { ReactComponent as IconFile } from "../../../../assets/file.svg";
import { ReactComponent as IconCheckbox } from "../../../../assets/checkbox.svg";
import { ReactComponent as IconDelete } from "../../../../assets/delete.svg";
import { ReactComponent as IconReminder } from "../../../../assets/clock2.svg";
//import { ReactComponent as IconCopy } from "../../../../assets/copy.svg";
//import { ReactComponent as IconPaste } from "../../../../assets/paste.svg";
//import { ReactComponent as IconMove } from "../../../../assets/move.svg";
import CommentsBadge from "../comments-badge/CommentsBadge";

import styles from "./pulp_branch.module.scss";

function reducer(state, action) {
  switch (action.type) {
    case "OPEN_TAG_SELECTOR":
      return {
        ...state,
        tagSelectorPos: action.payload,
        moveToSelectorPos: null,
      };
    case "OPEN_MOVE_TO_SELECTOR":
      return {
        ...state,
        tagSelectorPos: null,
        moveToSelectorPos: action.payload,
      };
    case "CLOSE_TAG_SELECTOR":
      return {
        ...state,
        tagSelectorPos: null,
      };
    case "CLOSE_MOVE_TO_SELECTOR":
      return {
        ...state,
        moveToSelectorPos: null,
      };
    default:
      return state;
  }
}

const initialValue = {
  tagSelectorPos: null,
  moveToSelectorPos: null,
};

export default function PulpBranch({
  children,
  isRoot,
  item,
  onCommand,
  hasChildren,
  hasBlocks,
}) {
  const ref = useRef();

  const [selectorState, dispatchSelectorState] = useReducer(
    reducer,
    initialValue
  );

  const tagList = useMemo(() => {
    return [
      {
        title: "BLOCKS",
        items: [
          {
            tag: "add-text-block",
            label: "Add text",
            commands: ["Shift", "Enter"],
            icon: <IconText />,
          },
          {
            tag: "add-work-block",
            label: "Add work item",
            payload: {
              tag: "work-item",
            },
            //commands: ["command", "I"],
            icon: <IconWork />,
          },
          {
            tag: "add-file-block",
            label: "Add file",
            payload: {
              tag: "file",
            },
            //commands: ["command", "F"],
            icon: <IconFile />,
          },
          {
            tag: "add-reminder-block",
            label: "Add reminder",
            payload: {
              tag: "reminder",
            },
            //commands: ["command", "R"],
            icon: <IconReminder />,
          },
        ],
      },
      /*{
        title: "ACTIONS",
        items: [
          {
            tag: "delete",
            label: "Delete",
            commands: ["command", "D"],
            icon: <IconDelete />,
          },
          {
            tag: "copy",
            label: "Copy",
            commands: ["command", "C"],
            icon: <IconCopy />,
          },
          {
            tag: "past",
            label: "Paste",
            commands: ["command", "V"],
            icon: <IconPaste />,
          },
        ],
      },*/
      {
        items: [
          item.meta.is_todo
            ? {
                tag: "remove-checkbox",
                label: "Remove checkbox",
                icon: <IconCheckbox />,
              }
            : {
                tag: "add-checkbox",
                label: "Add checkbox",
                //commands: ["command", "L"],
                icon: <IconCheckbox />,
              },
        ],
      },
      {
        title: "MOVE",
        items: [
          {
            tag: "move",
            label: "Move Up",
            commands: ["Shift", "command", "Arrow Up"],
            payload: {
              offset: -1,
            },
          },
          {
            tag: "move",
            label: "Move Down",
            commands: ["Shift", "command", "Arrow Down"],
            payload: {
              offset: 1,
            },
          },
          /*{
            tag: "move-to",
            label: "Move to",
            icon: <IconMove />,
          },*/
        ],
      },
      {
        title: "Indent",
        items: [
          {
            tag: "indent",
            label: "Indent right",
            commands: ["Tab"],
            payload: {
              dir: "right",
            },
          },
          {
            tag: "indent",
            label: "Indent left",
            commands: ["Shift", "Tab"],
            payload: {
              dir: "left",
            },
          },
        ],
      },
      {
        items: [
          {
            tag: "show-thread",
            label: "Comments",
            //commands: ["Shift", "command", "M"],
            icon: <IconComments />,
          },
        ],
      },
      {
        tag: "delete",
        label: "Delete",
        //commands: ['command', 'D'],
        icon: <IconDelete />,
      },
    ];
  }, [item.meta]);

  const handleDotsClick = (e) => {
    dispatchSelectorState({
      type: "OPEN_TAG_SELECTOR",
      payload: getOffset(e.target),
    });
  };

  const handleSelected = (tag) => {
    if (tag.tag === "move-to") {
      dispatchSelectorState({
        type: "OPEN_MOVE_TO_SELECTOR",
        payload: getOffset(ref.current),
      });
    } else {
      onCommand(tag);
    }
  };

  return (
    <div className={styles.branch} ref={ref}>
      {selectorState.tagSelectorPos ? (
        <TagSelectorMenu
          position={selectorState.tagSelectorPos}
          close={() => dispatchSelectorState({ type: "CLOSE_TAG_SELECTOR" })}
          onSelected={handleSelected}
        >
          {tagList}
        </TagSelectorMenu>
      ) : null}

      {selectorState.moveToSelectorPos ? (
        <MoveToSelectorMenuMenu
          position={selectorState.moveToSelectorPos}
          close={() =>
            dispatchSelectorState({ type: "CLOSE_MOVE_TO_SELECTOR" })
          }
          onSelected={console.log}
        ></MoveToSelectorMenuMenu>
      ) : null}

      {!isRoot && (
        <>
          {hasChildren ? (
            <button
              className={
                item.meta?.is_collapsed
                  ? styles.buttonArrow
                  : styles.buttonArrowOpen
              }
              onClick={() => onCommand({ tag: "toogle-collapsed" })}
            />
          ) : null}
          <button className={styles.buttonDots} onClick={handleDotsClick}>
            <IconDots />
          </button>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a href={"#" + item.id} className={styles.bullet} />

          <CommentsBadge
            onClick={() => onCommand({ tag: "show-thread" })}
            comments={item.comments}
            comments_read={item.comments_read}
          />

          {hasBlocks ? (
            <button
              className={styles.buttonPlus}
              onClick={() => onCommand({ tag: "add-text-block" })}
            >
              +
            </button>
          ) : null}
        </>
      )}
      {children && (
        <div
          className={
            styles.children +
            " " +
            (isRoot ? styles.root : "") +
            " " +
            (item.meta.is_todo ? styles.todo : "")
          }
        >
          {item.meta.is_todo ? (
            <input
              type="checkbox"
              checked={item.meta.is_checked || false}
              onChange={() =>
                onCommand({
                  tag: "toogle-checked",
                  payload: { is_checked: !item.meta.is_checked },
                })
              }
            />
          ) : null}
          {children}
        </div>
      )}
    </div>
  );
}
