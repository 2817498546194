import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import mixpanel from 'mixpanel-browser';

import { authQueryKeys } from "../constants/queryKeys";
import { AuthApi } from "../services/api/AuthApi";
import * as storage from "../services/storage";
import parseJWT from "../utils/parseJWT";

export default function useProvideAuth() {
  const [user, setUser] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!storage.getAccessToken()
  );

  const refetchQuery = useQuery({
    queryKey: authQueryKeys.me(),
    queryFn: AuthApi.Me,
    enabled: false,
    retry: false,
    onSuccess(data) {
      setUser(data);
    },
  });

  useEffect(() => {
    isAuthenticated && refetchQuery.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return {
    user,
    isAuthenticated,
    login(access_token) {
      const user = parseJWT(access_token);
      //mixpanel.identify(user.sub);
      //mixpanel.track('Login');
      storage.saveAccessToken(access_token);
      setIsAuthenticated(true);
    },
    logout() {
      mixpanel.track('Logout');
      mixpanel.reset();
      storage.clearTokens();
      setIsAuthenticated(false);
    },
    refetch: refetchQuery.refetch,
  };
}
