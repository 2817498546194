export const pulpApiQueryKeys = {
  all: () => ["pulp"],
  tree: (pulp) => [pulpApiQueryKeys.all(), "tree", { pulp }],
  roots: (access = 'viewer') => [pulpApiQueryKeys.all(), "roots", access],
  root: (id) => [pulpApiQueryKeys.roots(), id],
};

export const commentsQueryKeys = {
  all: () => ["comments"],
  for_: (object_id, content_type) => [
    commentsQueryKeys.all(),
    "for_",
    object_id,
    content_type,
  ],
};

export const workItemsKeys = {
  all: () => ["workItems"],
  changelog: (id) => [workItemsKeys.all(), id, "changelog"],
  detailsSubtee: (id) => [workItemsKeys.all(), id, 'details']
};

export const settingsKeys = {
  all: () => ["settings"],
  get: () => [settingsKeys.all(), "get"],
  root: (rootId) => [settingsKeys.all(), rootId, "root"],
};

export const boardKeys = {
  all: () => ["board"],
  workItems: (rootId) => [boardKeys.all(), rootId, "work-items"],
};

export const teamApiQueryKeys = {
  team: () => ["team"],
  members: (rootId) => [teamApiQueryKeys.team(), "members", rootId],
};

export const authQueryKeys = {
  me: () => ["me"],
};
