import { useQuery, useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { useAuth, useAvatarFeedback } from "../hooks";
import { SettingsApi } from "../services/api";
import { settingsKeys } from "../constants/queryKeys";
import { timezones } from "../constants/timezones";
import { useEffect } from "react";
import { Avatar } from "../components";
import { ReactComponent as IconUpload } from "../assets/upload.svg";

export default function SettingsView() {
  const avatarFeedback = useAvatarFeedback();
  const { refetch: refetchUserProfile } = useAuth();

  const { isLoading, isError, data, refetch: refetchSettings } = useQuery(settingsKeys.get(), () =>
    SettingsApi.Get()
  );

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('tz_name', data.tz_name);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const saveSettingsMutation = useMutation({
    mutationFn: SettingsApi.Save,
    onSuccess() {
      refetchUserProfile();
      avatarFeedback.reset();
    },
    onMutate() {
      avatarFeedback.indicateLoading();
    },
    onError() {
      avatarFeedback.indicateError();
    }
  });

  const saveProfile = (data) => {
    saveSettingsMutation.mutate(data);
  }

  const uploadPhotoMutation = useMutation({
    mutationFn: SettingsApi.UploadPhoto,
    onSuccess: () => {
      refetchSettings();
      refetchUserProfile();
      avatarFeedback.reset();
    },
    onMutate() {
      avatarFeedback.indicateLoading();
    },
    onError() {
      avatarFeedback.indicateError();
    }
  });

  const clearPhotoMutation = useMutation({
    mutationFn: SettingsApi.ClearPhoto,
    onSuccess: () => {
      refetchSettings();
      refetchUserProfile();
      avatarFeedback.reset();
    },
    onMutate() {
      avatarFeedback.indicateLoading();
    },
    onError() {
      avatarFeedback.indicateError();
    }
  });

  const onSelectFile = (e) => {
    const file = e.target.files[0];

    if (file.size > 10485760) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    uploadPhotoMutation.mutate(formData);
  };

  const onClearPhoto = () => {
    clearPhotoMutation.mutate()
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <div className="alarm">Error fetching settings</div>;
  }

  return (
    <div className="settings">
      <h1>Profile</h1>
      <div className="settingsInfo">
        <Avatar size={'large'} title={data?.name} photo={data?.photo_link} />
        <div>
          <h2>Profile Picture</h2>

          { uploadPhotoMutation.error && <div className="alert">Error uploading photo</div> }
          { clearPhotoMutation.error && <div className="alert">Error removing photo</div> }

          <div className="settingsButtons">
            <label htmlFor="upload_avatar" className={`button button-primary uploadButton ${uploadPhotoMutation.isLoading ? "button-primary-disabled" : ""}`}>
              <input
                id="upload_avatar"
                type="file"
                accept="image/png, image/jpeg, image/gif"
                onChange={onSelectFile}
                disabled={uploadPhotoMutation.isLoading}
              />
              <IconUpload />
              Upload image
            </label>
            <button className="button button-gray" disabled={clearPhotoMutation.isLoading} onClick={onClearPhoto}>Remove</button>
          </div>
          <p>We support PNGs, JPEGs and GIFs under 10MB</p>
        </div>
      </div>
      <form onSubmit={handleSubmit(saveProfile)} className="settingsForm">
        <div>
          <label>Name</label>
          <input type="text" {...register('name', {required: true})} />
        </div>

        <div>
          <label>Timezone</label>
          <select {...register('tz_name', {required: true})}>
            { timezones.map(tz => <option key={tz} value={tz}>{tz}</option>) }
          </select>
        </div>

        <div>
          <label htmlFor="email">Email Address</label>
          <input type="email" id="email" value={data.email} disabled />
          <Link to="/settings/change-email">
            Change email address
          </Link>
        </div>

        <div></div>

        <div>
          <button className="button button-primary" type="submit">Save</button>
        </div>
      </form>
    </div>
  )
}
