import { v4 as uuid } from "uuid";
import { Lexorank } from '../../../utils/Lexorank';


const lexorank = new Lexorank();

function newId() {
    return uuid();
}

function strcmp(a, b) {
  for (var i=0,n=Math.max(a.length, b.length); i<n && a.charAt(i) === b.charAt(i); ++i);
  if (i === n) return 0;
  return a.charAt(i) > b.charAt(i) ? 1 : -1;
}

function insertAt(arr, findObj, insertObj, offset) {
  const index = arr.indexOf(findObj);
  const left = arr.slice(0, index + offset);
  const right = arr.slice(index + offset, arr.length);
  const newArr = left.concat([insertObj], right);
  return newArr;
}

function findChildIndex(pulps, parentId, childId) {
  return pulps[parentId].indexOf(childId);
}

function removeChild(pulps, parent, child) {
  const childIndex = findChildIndex(pulps, parent, child);
  const siblings = Object.assign([], pulps[parent]);
  siblings.splice(childIndex, 1);
  return siblings;
}

function findNodeAbove(state, nodeId) {
  const parent = state.items[nodeId].parent;
  const siblings = state.pulps[parent];
  const ownIndex = siblings.indexOf(nodeId);

  if (siblings.length > 1 && ownIndex > 0) {
    let nearestRelative = siblings[ownIndex - 1];
    let relativeChildren = state.pulps[nearestRelative];
    // 2. Go through the last child of each relative, finding a child that has no children of its own:
    while (relativeChildren.length !== 0) {
      nearestRelative =
        state.pulps[nearestRelative][relativeChildren.length - 1];
      relativeChildren = state.pulps[nearestRelative];
    }
    return nearestRelative;
  } else {
    // 2. Go to our parent:
    return parent;
  }
}

function findNodeBelow(state, nodeId) {
  const ownChildren = state.pulps[nodeId];
  const parent = state.items[nodeId].parent;

  if (ownChildren.length > 0) {
    return ownChildren[0];
  } else {
    let currentNode = nodeId;
    let siblings = state.pulps[parent];
    let currentNodeIndex = siblings.indexOf(currentNode);

    while (siblings && currentNodeIndex === siblings.length - 1) {
      currentNode = state.items[currentNode].parent;
      siblings = state.pulps[state.items[currentNode].parent];
      if (!siblings) return null;
      currentNodeIndex = siblings.indexOf(currentNode);
    }

    const newFocus = siblings[currentNodeIndex + 1];
    return newFocus;
  }
}

function moveItem(state, id, offset) {
  const parent = state.items[id].parent;
  const siblings = Object.assign([], state.pulps[parent]);
  const index = siblings.indexOf(id);
  const ownItem = state.items[id];

  if (index === 0 && offset === -1) {
    return {}
  }

  if (index === siblings.length -1 && offset === 1) {
    return {}
  }

  let nextOne = null,
      nextNextOne = null,
      topItem = null,
      bottomItem = null;

  if (offset === -1) {
    nextOne = siblings[index - 1];
    nextNextOne = siblings[index - 2];

    topItem = nextNextOne ? state.items[nextNextOne] : null;
    bottomItem = nextOne ? state.items[nextOne] : null;
  } else if (offset === 1) {
    nextOne = siblings[index + 1];
    nextNextOne = siblings[index + 2];

    topItem = nextOne ? state.items[nextOne] : null;
    bottomItem = nextNextOne ? state.items[nextNextOne] : null;
  } else {
    new Error('Offset unknown');
  }

  const [rank, _ok] = lexorank.insert(topItem ? topItem.rank : '', bottomItem ? bottomItem.rank : '');

  const itemsUpdate = Object.assign({}, state.items, {
    [ownItem.id]: { ...ownItem, rank },
  });

  siblings[index] = siblings[index + offset];
  siblings[index + offset] = id;

  const pulpsUpdate = Object.assign({}, state.pulps, { [parent]: siblings })

  return {
    pulps: pulpsUpdate,
    items: itemsUpdate,
  };
}

function applyShiftLeft(state, action) {
  // When we shift left, we get parented to our grandparent,
  // if such a node exists. If it does, in the grandparent's
  // list of children, we should appear immediately after
  // our parent
  if (state.root === action.id) {
    return state;
  }
  // 1. Find grandparent:
  const ownId = action.id;
  const ownItem = state.items[ownId];
  const parent = ownItem.parent;
  const grandparent = state.items[parent].parent;

  // Make sure the parent isn't root:
  if (grandparent && parent !== state.root) {
    // 2. grandparent exists, remove self from parent's list
    // of children:
    let parentChildrenUpdate = removeChild(state.pulps, parent, ownId);
    // 3. become child of grandparent:
    const grandparentChildrenUpdate = insertAt(
      state.pulps[grandparent],
      parent,
      ownId,
      1
    );

    const ownIndexInGrandparent = grandparentChildrenUpdate.indexOf(ownId);

    const topItem = state.items[grandparentChildrenUpdate[ownIndexInGrandparent - 1]],
          bottomItem = state.items[grandparentChildrenUpdate[ownIndexInGrandparent + 1]];

    const [rank, _ok] = lexorank.insert(topItem ? topItem.rank : '', bottomItem ? bottomItem.rank : '');

    // 4. update our entry in items:
    const ownUpdate = Object.assign({}, state.items[ownId], {
      parent: grandparent,
      rank,
    });
    const itemsUpdate = Object.assign({}, state.items, { [ownId]: ownUpdate });

    const pulpsUpdate = Object.assign({}, state.pulps, {
      [grandparent]: grandparentChildrenUpdate,
      [parent]: parentChildrenUpdate,
    });

    return {
      items: itemsUpdate,
      pulps: pulpsUpdate,
      focus: { id: ownId, cursorPosition: action.cursorPosition },
    };
  } else {
    return {};
  }
}

function applyShiftRight(state, action) {
  // when we shift right, we get parented to the sibling above us
  // if there is such a sibling
  if (state.root === action.id) {
    return state;
  }
  const ownId = action.id;
  const ownItem = state.items[ownId];
  const siblings = Object.assign([], state.pulps[ownItem.parent]);
  const ownIndex = findChildIndex(state.pulps, ownItem.parent, ownId);
  const precedingSiblingsExist = ownIndex > 0;
  if (precedingSiblingsExist) {
    // Reparent the current node to the immediately
    // preceding sibling:
    // 1. parent self to the upper sibling
    const siblingIndex = ownIndex - 1;
    const siblingId = siblings[siblingIndex];
    const siblingChildren = Object.assign([], state.pulps[siblingId]);

    const [rank, _ok] = lexorank.insert(siblingChildren.length > 0 ? state.items[siblingChildren[siblingChildren.length -1]].rank : null);

    siblingChildren.push(ownId);
    // 2. remove self from current parent
    siblings.splice(ownIndex, 1);
    // 3. assign the update to pulps:
    const pulpsUpdate = Object.assign({}, state.pulps, {
      [siblingId]: siblingChildren,
      [ownItem.parent]: siblings,
    });
    // 4. Update own item entry:

    const itemsUpdate = Object.assign({}, state.items, {
      [ownId]: Object.assign({}, state.items[ownId], { parent: siblingId, rank }),
    });
    return {
      items: itemsUpdate,
      pulps: pulpsUpdate,
      focus: { id: ownId, cursorPosition: action.cursorPosition },
    };
  } else {
    return {};
  }
}

export {
    insertAt,
    findChildIndex,
    removeChild,
    findNodeAbove,
    findNodeBelow,
    moveItem,
    applyShiftLeft,
    applyShiftRight,
    newId,
    lexorank,
    strcmp,
}