export default function ApiError({ error }) {
    let errorText = 'Error';

    if (typeof error.response?.data?.detail === 'string') {
        errorText = error.response.data.detail
    } else if (Array.isArray(error.response?.data?.detail)) {
        errorText = error.response.data.detail.map(e => e.msg).join('\n')
    }

    return <div className="notification notification-alarm">{errorText}</div>
}