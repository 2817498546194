import Commands from '../commands/Commands';
import styles from './action_item.module.scss';

const ActionItem = ({ className, icon, title, text, commands, handleClick }) => (
  <button className={`${styles.button} ${className || ''}`} onClick={handleClick}>
    <span className={styles.buttonText}>
      {icon}
      <div>{title}</div>
    </span>
    <Commands commands={commands} />
  </button>
);

export default ActionItem;