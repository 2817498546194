import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { PulpApi } from '../services/api'
import { pulpApiQueryKeys } from '../constants/queryKeys'
import { AsideMenu } from '../components'

export default function SettingsSidebar({ isSidebarOpen }) {
  const { isLoading, isError, data } = useQuery(
    pulpApiQueryKeys.roots('editor'),
    () => PulpApi.Roots('editor')
  )

  if (isLoading) {
    return <p>loading...</p>
  }

  if (isError) {
    return <div className='alarm'>Error fetching settings</div>
  }

  return (
    <aside className={isSidebarOpen ? "sidebar" : "sidebar sidebarHide"}>
      <div className="sidebarTitle">Account</div>
      <Link to={'/settings/'} className="sidebarProfile">Profile</Link>
      <div className="sidebarTitle">Roots</div>
      <AsideMenu items={data} routePrefix="/settings" />
    </aside>
  )
}
