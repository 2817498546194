import { useState } from "react";
import { ModalContext } from "../../contexts";

export default function ModalProvider({ children }) {
  const [modalsConfig, setConfig] = useState({});

  const hideModal = (modalKey, onCloseResult) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [modalKey]: { ...prevConfig[modalKey], isOpen: false },
    }));
    if (modalsConfig[modalKey].onClose) {
      modalsConfig[modalKey].onClose(onCloseResult);
    }
  };

  const showModal = (modalKey, component, modalData, onClose) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [modalKey]: { isOpen: true, component, data: modalData, onClose },
    }));
  };

  const contextValue = {
    showModal,
    hideModal,
    isOpenedModal: Object.values(modalsConfig).some(({ isOpen }) => isOpen),
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {Object.keys(modalsConfig).map((modalKey) => {
        const { component: Component, isOpen, data } = modalsConfig[modalKey];

        return (
          isOpen && (
            <Component
              onClose={(result) => hideModal(modalKey, result)}
              key={modalKey}
              isOpen={isOpen}
              {...data}
            />
          )
        );
      })}
    </ModalContext.Provider>
  );
}
