import { useRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useOutsideClick } from "../../../../hooks";
import { ReactComponent as Docs } from "../../../../assets/docs.svg";
import styles from "./styles.module.scss";

const placeholderList = [
  'Untitled',
  'Important things',
  'Super duper document'
];
const portal = document.querySelector('#root');

const MoveToSelectorMenuMenu = ({ position, close }) => {
  const ref = useRef();
  useOutsideClick(ref, () => close());

  const [xy, setXY] = useState({ x: -999, y: -999 });


  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        close();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const rect = ref.current.getBoundingClientRect();

    let x = position.x;
    let y = position.y;

    if (position.x + rect.width > window.innerWidth + window.scrollX) {
      x = position.x - rect.width;
    }

    if (position.y + rect.height > window.innerHeight + window.scrollY) {
      y = Math.max(55, position.y - rect.height)
    }

    setXY({ x, y })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const children = (
    <div
      ref={ref}
      className={styles.menuWrapper}
      style={{
        top: xy.y,
        left: xy.x,
      }}
    >
      <form action="" className={styles.form}>
        <input type="submit" value="Search" />
        <input type="text" placeholder="Move to..." autoFocus />
      </form>
      <h3>Suggested</h3>
      <ul>
        {placeholderList.map((item, i) => <li key={i}><a href='#'><Docs/>{item}</a></li>)}
      </ul>
    </div>
  );

  return createPortal(children, portal);
};

export default MoveToSelectorMenuMenu;
