import image from "../assets/michael-scott-the-office.webp";

export default function ErrorBoundary() {
  return (
    <div id="error-page">
      <h1>Oops! Unexpected Error</h1>
      <p>Something went wrong.</p>
      <p>
        <a href="javascript:location.reload()">Reload</a>
      </p>
      <img src={image} style={{'width': '60vw'}} alt="Something went wrong" />
    </div>
  );
}
