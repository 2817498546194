import { $host } from "./host";

export class SettingsApi {
  static async Get() {
    const { data } = await $host.get(`/api/v1/settings/`);
    return data;
  }

  static async Save(obj) {
    const { data } = await $host.post(`/api/v1/settings/`,  obj);
    return data;
  }

  static async GetRoot(rootId) {
    const { data } = await $host.get(`/api/v1/settings/root/${rootId}/`);
    return data;
  }

  static async SavePartialRoot({key, delta}) {
    const { data } = await $host.patch(`/api/v1/settings/root/${key}/`, delta);
    return data
  }

  static async UploadPhoto(formData) {
    return await $host.post(
      `/api/v1/settings/photo/`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  }

  static async ClearPhoto() {
    await $host.post(`/api/v1/settings/photo-clear/`);
  }
}
