import { features } from '../../../constants/homeContent';

export default function Features({ classes }) {
  return <div className={classes.cards}>
    {features.map(({ id, content, image, title}) => <div key={id} className={classes.card}>
      <div className={classes.cardImage}>
        <img src={image} alt={title} />
      </div>
      <h3>{title}</h3>
      {content}
    </div>)}
  </div>
}