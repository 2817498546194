import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import queryClient from "../constants/queryClient";
import { PulpApi } from "../services/api";
import { pulpApiQueryKeys } from "../constants/queryKeys";
import { AsideMenu, ActionItem } from "../components";
import { ReactComponent as IconPlus } from "../assets/icon-plus.svg";
import { ReactComponent as IconDelete } from "../assets/delete.svg";
import { ReactComponent as IconShare } from "../assets/share.svg";
import { useDeletePulp, useSharePulp } from "../views/PulpView/hooks";
//import Search from "../components/search/Search";

const tagList = [
  {
    tag: "share",
    label: "Share",
    icon: <IconShare />,
  },
  {
    tag: "delete",
    label: "Delete",
    icon: <IconDelete />,
  },
];

export default function PulpSidebar({ isSidebarOpen }) {
  const { key: selectedPulpKey } = useParams();
  const navigate = useNavigate();
  const sharePulp = useSharePulp();
  const deletePulp = useDeletePulp(function(obj) {
    if (obj.id) {
      queryClient.setQueryData(pulpApiQueryKeys.roots(), (old) => old.filter(o => o.id !== obj.id));
      if (selectedPulpKey === obj.id) {
        navigate('/')
      }
    }
  });

  const { isLoading, isError, data } = useQuery(
    pulpApiQueryKeys.roots(),
    () => PulpApi.Roots()
  );

  const createRootMutation = useMutation({
    mutationFn: PulpApi.CreateRoot,
    onSuccess: ({ data }) => {
      queryClient.setQueryData(pulpApiQueryKeys.roots(), (old) => [
        ...old,
        data,
      ]);
    },
  });

  const addNewRoot = () => {
    createRootMutation.mutate({ content: "Untitled" });
  };

  const onContextMenu = ({ tag, payload }) => {
    if (tag === "delete") {
      deletePulp(payload);
    } else if (tag === 'share') {
      sharePulp(payload)
    }
  };

  if (isLoading) {
    return <p>loading...</p>;
  }

  if (isError) {
    return <div className="alarm">Error fetching sidebar</div>;
  }

  return (
    <aside className={isSidebarOpen ? "sidebar" : "sidebar sidebarHide"}>
      <AsideMenu
        items={data}
        contextMenu={tagList}
        onContextMenu={onContextMenu}
      />
      <ActionItem
        className="sidebarAddButton"
        //commands={["command", "N"]}
        handleClick={addNewRoot}
        icon={<IconPlus />}
        title="New root"
      />
    </aside>
  );
}
