import { useReducer, useMemo } from "react";

import { avatarFeedbackContext } from "../contexts";

function reducer(state, action) {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: true, isError: false };
    case "RESET":
      return { ...state, isLoading: false, isError: false };
    case "ERROR":
      return { ...state, isLoading: false, isError: true };
    default:
      return state;
  }
}

const initialValue = {
  isLoading: false,
  isError: false,
};

export default function ProvideAvatarFeedback({ children }) {
  const [state, dispatch] = useReducer(reducer, initialValue);

  const value = useMemo(() => {
    return {
      indicateLoading() {
        dispatch({ type: "LOADING" });
      },
      indicateError() {
        dispatch({ type: "ERROR" });
      },
      reset() {
        dispatch({ type: "RESET" });
      },
      isLoading: state.isLoading,
      isError: state.isError,
    };
  }, [state]);

  return (
    <avatarFeedbackContext.Provider value={value}>
      {children}
    </avatarFeedbackContext.Provider>
  );
}
