import { useState, useEffect, useRef } from "react";
import styles from "./inline_textarea.module.scss";

export default function InlineTextarea({ value, onChange, placeholder }) {
  const currentField = useRef(null);

  const [localValue, setLocalValue] = useState(value);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (isEdit) {
      currentField.current.select();
      currentField.current.style.height =
        currentField.current.scrollHeight + "px";
    }
  }, [isEdit]);

  const handleOnChange = (e) => {
    const val = e.target.value.trimStart();
    setLocalValue(val)
  };

  const handleSave = () => {
    onChange(localValue)
    setIsEdit(false)
  }

  const handleCancel = () => {
    setLocalValue(value)
    setIsEdit(false)
  }

  return (
    <div className="w-100">
      {isEdit ? (
        <>
            <textarea
              value={localValue}
              onChange={handleOnChange}
              className={styles.textarea + ' form-control'}
              ref={currentField}
            />
            <div className={styles.buttons}>
              <button className="button button-primary" onClick={handleSave}>Save</button>
              <button className="button button-gray" onClick={handleCancel}>Cancel</button>
            </div>
        </>
      ) : (
        <pre className={styles.div} onClick={() => setIsEdit(true)}>
          {localValue || placeholder}
        </pre>
      )}
    </div>
  );
}
