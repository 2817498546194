import { $host } from "./host";

export class TeamApi {
  static async ShareRoot({ key, email, access }) {
    const { data } = await $host.post(`/api/v1/team/${key}/share/`, {
      email,
      access,
    });
    return data;
  }

  static async UpdateAccess({ key, email, access }) {
    const { data } = await $host.post(`/api/v1/team/${key}/update-access/`, {
      email,
      access,
    });
    return data;
  }

  static async DeleteShare({ key, member_id }) {
    await $host.delete(`/api/v1/team/${key}/share/`, { params: { member_id } });
  }

  static async Members(key) {
    const { data } = await $host.get(`/api/v1/team/${key}/members/`);
    return data;
  }
}
