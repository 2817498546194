import { createContext } from "react";

export const PulpBreadcrumbsContext = createContext();

export const LightboxContext = createContext();

export const ModalContext = createContext({
    showModal: null,
    hideModal: null,
    isOpenedModal: false,
});

export const authContext = createContext();

export const avatarFeedbackContext = createContext();
