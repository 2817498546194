export function toNiceDateString(dt) {
    return new Date(dt).toLocaleDateString();
}

export function toNiceDateTimeString(dt, timeZone) {
    try {
        return new Date(dt).toLocaleString('en-GB', {timeZone, dateStyle: 'short', timeStyle: 'short'})
    } catch {
        return new Date(dt).toLocaleString()
    }
}
