import { useEffect, useRef } from "react";

import styles from "./pulp_branch_content.module.scss";

export default function PulpBranchContent({
  item,
  cursorPosition,
  onAddNewBlock,
  onAddNewPulp,
  onEdit,
  onMove,
  onTab,
  onArrow,
  onDeleteItem,
}) {
  const nodeRef = useRef();

  useEffect(() => {
    if (cursorPosition != null) {
      const node = nodeRef.current;
      var range = document.createRange();
      var sel = window.getSelection();
      range.setStart(node.childNodes[0] || node, cursorPosition);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      node.focus();
    }
  }, [cursorPosition]);

  const handleChanges = (e) => {
    onEdit({ content: e.target.innerText });
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "Enter":
        e.preventDefault();
        if (e.shiftKey) {
          onAddNewBlock();
        } else {
          onAddNewPulp();
        }
        break;
      case "Tab":
        e.preventDefault();
        let dir;
        if (e.shiftKey) {
          dir = "left";
        } else {
          dir = "right";
        }
        onTab(dir);
        break;
      case "ArrowUp":
      case "ArrowDown":
        e.preventDefault();
        if (e.metaKey && e.shiftKey) {
          onMove(e.key === "ArrowUp" ? -1 : 1);
        } else {
          onArrow(e.key === "ArrowUp" ? "up" : "down");
        }
        break;
      case "Backspace":
        if (e.target.textContent === '' && item.content.replace('<br>', '') === '') {
          e.preventDefault();
          onDeleteItem();
        }
        break;
      default:
        return null;
    }
  };

  return (
    <>
      <div
        ref={nodeRef}
        data-title="titleBranch"
        contentEditable={true}
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={{ __html: item.content }}
        className={styles.editor}
        onInput={handleChanges}
        onKeyDown={handleKeyDown}
      />
      { !item.content ? <div className={styles.placeholder}>New branch</div> : null }
    </>
  );
}
