import {ReactComponent as IconC} from "../../assets/icon-c.svg";
import {ReactComponent as IconD} from "../../assets/icon-d.svg";
import {ReactComponent as IconF} from "../../assets/icon-f.svg";
import {ReactComponent as IconH} from "../../assets/icon-h.svg";
import {ReactComponent as IconI} from "../../assets/icon-i.svg";
import {ReactComponent as IconL} from "../../assets/icon-l.svg";
import {ReactComponent as IconN} from "../../assets/icon-n.svg";
import {ReactComponent as IconM} from "../../assets/icon-m.svg";
import {ReactComponent as IconK} from "../../assets/icon-k.svg";
import {ReactComponent as IconP} from "../../assets/icon-p.svg";
import {ReactComponent as IconR} from "../../assets/icon-r.svg";
import {ReactComponent as IconS} from "../../assets/icon-s.svg";
import {ReactComponent as IconT} from "../../assets/icon-t.svg";
import {ReactComponent as IconV} from "../../assets/icon-v.svg";
import {ReactComponent as IconShift} from "../../assets/icon-shift.svg";
import {ReactComponent as IconCommand} from "../../assets/icon-command.svg";

import styles from "./commands.module.scss";

const Commands = ({ commands, isSmall }) => {
  if (!commands?.length) return null;

  const commandsIcon = commands.map((icon) => {
    switch (icon) {
      case 'C': return <span key={icon} className={styles.commandsIcon}><IconC /></span>;
      case 'D': return <span key={icon} className={styles.commandsIcon}><IconD /></span>;
      case 'F': return <span key={icon} className={styles.commandsIcon}><IconF /></span>;
      case 'H': return <span key={icon} className={styles.commandsIcon}><IconH /></span>;
      case 'I': return <span key={icon} className={styles.commandsIcon}><IconI /></span>;
      case 'L': return <span key={icon} className={styles.commandsIcon}><IconL /></span>;
      case 'N': return <span key={icon} className={styles.commandsIcon}><IconN /></span>;
      case 'M': return <span key={icon} className={styles.commandsIcon}><IconM /></span>;
      case 'K': return <span key={icon} className={styles.commandsIcon}><IconK /></span>;
      case 'P': return <span key={icon} className={styles.commandsIcon}><IconP /></span>;
      case 'R': return <span key={icon} className={styles.commandsIcon}><IconR /></span>;
      case 'S': return <span key={icon} className={styles.commandsIcon}><IconS /></span>;
      case 'T': return <span key={icon} className={styles.commandsIcon}><IconT /></span>;
      case 'V': return <span key={icon} className={styles.commandsIcon}><IconV /></span>;
      case 'Shift': return <span key={icon} className={styles.commandsIcon}><IconShift /></span>;
      case 'Arrow Up': return <span key={icon} className={styles.commandsIcon}>↑</span>;
      case 'Arrow Down': return <span key={icon} className={styles.commandsIcon}>↓</span>;
      case 'Enter': return <span key={icon} className={styles.commandsIcon}>⏎</span>;
      case 'Tab': return <span key={icon} className={styles.commandsIcon}>Tab</span>;
      default: return <span key={icon} className={styles.commandsIcon}><IconCommand /></span>;
    }
  });

  return  (
    <div className={styles.commands}>{commandsIcon}</div>
  )
};

export default Commands;