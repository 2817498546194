import { $host } from "./host";

export class BoardApi {
  static async WorkItems(id, session_id) {
    const { data } = await $host.get(`/api/v1/work-items/board/${id}/`, { params: { session_id } });
    return data;
  }

  static async Pull({key, session_id, client_state}) {
    const { data } = await $host.post(`/api/v1/work-items/board/${key}/pull/`, { session_id, client_state });
    return data;
  }
}
