export function saveAccessToken(accessToken) {
  localStorage.setItem('accessToken', accessToken)
}

export function getAccessToken() {
  return localStorage.getItem('accessToken')
}

export function clearTokens() {
    localStorage.removeItem('accessToken')
}