import { useReducer, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import workItemIcons from '../constants/workItemIcons';
import { SettingsApi, PulpApi } from "../services/api";
import { settingsKeys, pulpApiQueryKeys } from "../constants/queryKeys";
import { ReactComponent as Delete } from "../assets/delete.svg";
import { useModal } from "../hooks";
import { RootSettingsDeleteWCItemModal } from "./PulpView/components";


const initialValue = {
  showAddWorkItemType: false,
  showAddStatus: false,
  showAddWorkType: false,

  newWorkItemType: "",
  newWorkItemTypeIconIndex: 0,
  newStatus: "",
  newWorkType: "",

  isSavingWorkItemType: false,
  isSavingStatus: false,
  isSavingWorkType: false,

  isErrorSavingWorkItemType: false,
  isErrorSavingStatus: false,
  isErrorSavingWorkType: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SLIDE_WORK_ITEM_ICON':
      const newWorkItemTypeIconIndex = ++state.newWorkItemTypeIconIndex % workItemIcons.length;
      return { ...state, newWorkItemTypeIconIndex: newWorkItemTypeIconIndex };

    case "ADD_WORK_ITEM_TYPE":
      return { ...state, showAddWorkItemType: true };
    case "ADD_STATUS":
      return { ...state, showAddStatus: true };
    case "ADD_WORK_TYPE":
      return { ...state, showAddWorkType: true };

    case "HIDE_WORK_ITEM_TYPE":
      return { ...state, showAddWorkItemType: false };
    case "HIDE_STATUS":
      return { ...state, showAddStatus: false };
    case "HIDE_WORK_TYPE":
      return { ...state, showAddWorkType: false };

    case "EDIT_WORK_ITEM_TYPE":
      return { ...state, newWorkItemType: action.payload };
    case "EDIT_STATUS":
      return { ...state, newStatus: action.payload };
    case "EDIT_WORK_TYPE":
      return { ...state, newWorkType: action.payload };

    case "SAVING_WORK_ITEM_TYPE":
      return { ...state, isSavingWorkItemType: true };
    case "SAVING_STATUS":
      return { ...state, isSavingStatus: true };
    case "SAVING_WORK_TYPE":
      return { ...state, isSavingWorkType: true };

    case "OK_SAVING_WORK_ITEM_TYPE":
    case "ERROR_SAVING_WORK_ITEM_TYPE":
      return {
        ...state,
        showAddWorkItemType: action.type === "ERROR_SAVING_WORK_ITEM_TYPE",
        isSavingWorkItemType: false,
        isErrorSavingWorkItemType:
          action.type === "ERROR_SAVING_WORK_ITEM_TYPE",
        newWorkItemType:
          action.type === "ERROR_SAVING_WORK_ITEM_TYPE"
            ? state.newWorkItemType
            : "",
      };
    case "OK_SAVING_STATUS":
    case "ERROR_SAVING_STATUS":
      return {
        ...state,
        showAddStatus: action.type === "ERROR_SAVING_STATUS",
        isSavingStatus: false,
        isErrorSavingStatus: action.type === "ERROR_SAVING_STATUS",
        newStatus: action.type === "ERROR_SAVING_STATUS" ? state.newStatus : "",
      };
    case "OK_SAVING_WORK_TYPE":
    case "ERROR_SAVING_WORK_TYPE":
      return {
        ...state,
        showAddWorkType: action.type === "ERROR_SAVING_WORK_TYPE",
        isSavingWorkType: false,
        isErrorSavingWorkType: action.type === "ERROR_SAVING_WORK_TYPE",
        newWorkType:
          action.type === "ERROR_SAVING_WORK_TYPE" ? state.newWorkType : "",
      };

    default:
      return state;
  }
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function RootSettingsView() {
  const { key } = useParams();

  const [statuses, setStatuses] = useState([]);
  const [workTypes, setWorkTypes] = useState([]);
  const [workItemTypes, setWorkItemTypes] = useState([]);

  const [state, dispatch] = useReducer(reducer, initialValue);

  const rootQuery = useQuery(pulpApiQueryKeys.root(key), () =>
    PulpApi.Root(key)
  );

  function setStates(data) {
    const statuses = data.statuses;
    statuses.sort((a, b) => a.order - b.order);
    setStatuses(statuses);

    setWorkItemTypes(data.work_item_types);
    setWorkTypes(data.work_types);
  }

  const rootSettingsQuery = useQuery(
    settingsKeys.root(key),
    () => SettingsApi.GetRoot(key),
    {
      onSuccess: setStates,
    }
  );

  // eslint-disable-next-line no-unused-vars
  const [showDeleteModal, hideDeleteModal] = useModal(
    RootSettingsDeleteWCItemModal,
    {},
    (result) => {
      if (result) {
        setStates(result)
      }
    }
  );

  const savePartialRootMutation = useMutation({
    mutationFn: SettingsApi.SavePartialRoot,
    onSuccess: setStates,
  });

  function onDeleteWorkItemType(id) {
    showDeleteModal({
      id,
      title: "Delete work item type?",
      header: "Select a replacement",
      options: workItemTypes,
      defaultOptionId: workItemTypes.filter(
        (o) => o.id !== id
      )[0].id,
      getDelta({ replacement }) {
        return {
          key: key,
          delta: {
            deleteWorkItemTypeId: id,
            replacementWorkItemTypeId: replacement,
          },
        };
      },
    });
  }

  function onNewWorkItemTypeKeyDown(e) {
    if (savePartialRootMutation.isLoading) {
      return;
    }

    if ((e.key === "Enter" || e.type === "click") && state.newWorkItemType) {
      dispatch({ type: "SAVING_WORK_ITEM_TYPE" });

      savePartialRootMutation.mutate(
        { key, delta: { newWorkItemType: state.newWorkItemType, newWorkItemTypeIcon: workItemIcons[state.newWorkItemTypeIconIndex] } },
        {
          onSuccess() {
            dispatch({ type: "OK_SAVING_WORK_ITEM_TYPE" });
          },
          onError() {
            dispatch({ type: "ERROR_SAVING_WORK_ITEM_TYPE" });
          },
        }
      );
    }
  }

  function onDeleteWorkType(id) {
    showDeleteModal({
      id,
      title: "Delete work type?",
      getDelta() {
        return {
          key: key,
          delta: {
            deleteWorkTypeId: id,
          },
        };
      },
    });
  }

  function onNewWorkTypeKeyDown(e) {
    if (savePartialRootMutation.isLoading) {
      return;
    }

    if ((e.key === "Enter" || e.type === "click") && state.newWorkType) {
      dispatch({ type: "SAVING_WORK_TYPE" });

      savePartialRootMutation.mutate(
        { key, delta: { newWorkType: state.newWorkType } },
        {
          onSuccess() {
            dispatch({ type: "OK_SAVING_WORK_TYPE" });
          },
          onError() {
            dispatch({ type: "ERROR_SAVING_WORK_TYPE" });
          },
        }
      );
    }
  }

  function onDeleteStatus(id) {
    showDeleteModal({
      id,
      title: "Delete status?",
      header: "Select a replacement",
      options: statuses,
      defaultOptionId: statuses.filter(
        (o) => o.id !== id
      )[0].id,
      getDelta({ replacement }) {
        return {
          key: key,
          delta: {
            deleteStatusId: id,
            replacementStatusId: replacement,
          },
        };
      },
    });
  }

  function onNewStatusKeyDown(e) {
    if (savePartialRootMutation.isLoading) {
      return;
    }

    if ((e.key === "Enter" || e.type === "click") && state.newStatus) {
      dispatch({ type: "SAVING_STATUS" });

      savePartialRootMutation.mutate(
        { key, delta: { newStatus: state.newStatus } },
        {
          onSuccess() {
            dispatch({ type: "OK_SAVING_STATUS" });
          },
          onError() {
            dispatch({ type: "ERROR_SAVING_STATUS" });
          },
        }
      );
    }
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const reorderedStatuses = reorder(
      statuses,
      result.source.index,
      result.destination.index
    );

    savePartialRootMutation.mutate({
      key,
      delta: {
        statusesOrder: reorderedStatuses.reduce((acc, status, index) => {
          acc[status.id] = index;
          return acc;
        }, {}),
      },
    });

    setStatuses(reorderedStatuses);
  }

  return (
    <div className="settings">
      <h1>
        {rootQuery.isLoading ? (
          "Loading..."
        ) : rootQuery.isError ? (
          <div className="alarm">Error fetching root</div>
        ) : (
          rootQuery.data.content
        )}
      </h1>

      <div className="settingsSection">
        <h2>Work item types</h2>
        <ul className="settingsList">
          {workItemTypes.map(({ id, name, icon }) => (
            <li key={id}>
              <div className="settingsListTitle">
                <img src={icon} alt={name} className="settingsListIcon" />
                {name}
              </div>

              {workItemTypes.length > 1 ? (
                <button
                  className="settingsListButton"
                  onClick={() => onDeleteWorkItemType(id)}
                >
                  <Delete />
                </button>
              ) : null}
            </li>
          ))}
          {state.showAddWorkItemType ? (
            <li>
                <button className="settingsListButton" onClick={() => dispatch({ type: 'SLIDE_WORK_ITEM_ICON' })}>
                  <img src={workItemIcons[state.newWorkItemTypeIconIndex]} alt={'Icon'} className="settingsListIcon" />
                </button>
              <div className="settingsListTitle">
                <input
                  type="text"
                  value={state.newWorkItemType}
                  disabled={state.isSavingWorkItemType}
                  onKeyDown={onNewWorkItemTypeKeyDown}
                  onChange={(e) =>
                    dispatch({
                      type: "EDIT_WORK_ITEM_TYPE",
                      payload: e.target.value,
                    })
                  }
                />
              </div>
              <button
                className="settingsListButton"
                onClick={() => dispatch({ type: "HIDE_WORK_ITEM_TYPE" })}
              >
                <Delete />
              </button>
            </li>
          ) : null}
        </ul>
        {state.showAddWorkItemType ? (
          <button
            className="button button-gray button-fullwidth"
            onClick={onNewWorkItemTypeKeyDown}
          >
            Save work item type
          </button>
        ) : (
          <button
            className="button button-gray button-fullwidth"
            onClick={() => dispatch({ type: "ADD_WORK_ITEM_TYPE" })}
          >
            Add work item type
          </button>
        )}
      </div>
      <div className="settingsSection">
        <h2>Statuses</h2>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="settingsList"
              >
                {statuses.map(({ id, name, color }, index) => (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        className="settingsListItem settingsListItemMove"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="settingsListTitle">
                          <span
                            className="settingsListColor"
                            style={{ backgroundColor: `${color}` }}
                          />
                          {name}
                        </div>
                        {statuses.length > 1 ? (
                          <button
                            className="settingsListButton"
                            onClick={() => onDeleteStatus(id)}
                          >
                            <Delete />
                          </button>
                        ) : null}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {state.showAddStatus ? (
          <div className="settingsListItem settingsListItemAdd">
            <div className="settingsListTitle">
              <input
                type="text"
                value={state.newStatus}
                disabled={state.isSavingStatus}
                onKeyDown={onNewStatusKeyDown}
                onChange={(e) =>
                  dispatch({
                    type: "EDIT_STATUS",
                    payload: e.target.value,
                  })
                }
              />
            </div>
            <button
              className="settingsListButton"
              onClick={() => dispatch({ type: "HIDE_STATUS" })}
            >
              <Delete />
            </button>
          </div>
        ) : null}
        {state.showAddStatus ? (
          <button
            className="button button-gray button-fullwidth"
            onClick={onNewStatusKeyDown}
          >
            Save status
          </button>
        ) : (
          <button
            className="button button-gray button-fullwidth"
            onClick={() => dispatch({ type: "ADD_STATUS" })}
          >
            Add status
          </button>
        )}
      </div>
      <div className="settingsSection">
        <h2>Work types</h2>
        <ul className="settingsList">
          {workTypes.map(({ id, name }) => (
            <li key={id}>
              <div className="settingsListTitle">{name}</div>
              {workTypes.length > 1 ? (
                <button
                  className="settingsListButton"
                  onClick={() => onDeleteWorkType(id)}
                >
                  <Delete />
                </button>
              ) : null}
            </li>
          ))}
          {state.showAddWorkType ? (
            <li>
              <div className="settingsListTitle">
                <input
                  type="text"
                  disabled={state.isSavingWorkType}
                  value={state.newWorkType}
                  onKeyDown={onNewWorkTypeKeyDown}
                  onChange={(e) =>
                    dispatch({
                      type: "EDIT_WORK_TYPE",
                      payload: e.target.value,
                    })
                  }
                />
              </div>
              <button
                className="settingsListButton"
                onClick={() => dispatch({ type: "HIDE_WORK_TYPE" })}
              >
                <Delete />
              </button>
            </li>
          ) : null}
        </ul>
        {state.showAddWorkType ? (
          <button
            className="button button-gray button-fullwidth"
            onClick={onNewWorkTypeKeyDown}
          >
            Save work type
          </button>
        ) : (
          <button
            className="button button-gray button-fullwidth"
            onClick={() => dispatch({ type: "ADD_WORK_TYPE" })}
          >
            Add work type
          </button>
        )}
      </div>
    </div>
  );
}
