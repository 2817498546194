import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import getOffset from "../../utils/getOffset";
import { limitString } from "../../utils/limitString";
import TagSelectorMenu from "../../views/PulpView/components/tag-selector-menu/TagSelectorMenu";
import styles from "./aside_menu.module.scss";

const MenuItem = ({
  id,
  isActive,
  title,
  routePrefix,
  onDotsClick,
  showDots,
}) => {
  return (
    <Link
      to={`${routePrefix}/p/${id}`}
      title={title}
      className={isActive ? styles.menuItemActive : styles.menuItem}
    >
      {limitString(title)}

      {showDots ? (
        <button
          className={styles.menuItemDots}
          onClick={(e) => {
            e.preventDefault();
            onDotsClick({ id, offset: getOffset(e.target) });
          }}
        />
      ) : null}
    </Link>
  );
};

const AsideMenu = ({ items, routePrefix, contextMenu, onContextMenu }) => {
  const [tagSelector, setTagSelector] = useState(null);

  routePrefix = routePrefix || "";

  const { pathname } = useLocation();

  const handleDotsClick = (obj) => {
    setTagSelector({
      ...obj,
      tagList: contextMenu.map((t) => {
        return { ...t, payload: { id: obj.id } };
      }),
    });
  };

  return (
    <>
      <ul className={styles.menu}>
        {items.map(({ id, content }) => (
          <li key={id}>
            <MenuItem
              id={id}
              title={content}
              routePrefix={routePrefix}
              isActive={pathname === `${routePrefix}/p/${id}`}
              onDotsClick={handleDotsClick}
              showDots={!!contextMenu}
            />
          </li>
        ))}
      </ul>
      {tagSelector ? (
        <TagSelectorMenu
          position={tagSelector.offset}
          close={() => setTagSelector(null)}
          onSelected={onContextMenu}
        >
          {tagSelector.tagList}
        </TagSelectorMenu>
      ) : null}
    </>
  );
};

export default AsideMenu;
