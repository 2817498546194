import { Outlet } from "react-router-dom";
import { useState } from "react";

import { ProvideAvatarFeedback } from "../components";

import { PulpBreadcrumbsContext } from "../contexts";

import Navbar from "./Navbar";

export default function AnonPulpLayout() {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  return (
    <div className={"container-full"}>
      <ProvideAvatarFeedback>
        <Navbar
          breadcrumbs={breadcrumbs}
          isSidebarOpen={false}
        />
        <div className="content">
          <PulpBreadcrumbsContext.Provider value={{ setBreadcrumbs }}>
            <Outlet />
          </PulpBreadcrumbsContext.Provider>
        </div>
      </ProvideAvatarFeedback>
    </div>
  );
}
