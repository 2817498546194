import Clean from "./Clean";
import Draft from "./Draft";

export default function PulpFile({ item, pulpId, blockId, onFileUploaded, onDeleteFile }) {
  return item.draft ? (
    <Draft {...{ onFileUploaded, blockId, pulpId, item }} />
  ) : (
    <Clean { ...{blockId, pulpId, item, onDeleteFile} } />
  );
}
