import Commands from '../../../components/commands/Commands';
import { shortcuts } from '../../../constants/homeContent';

export default function Shortcuts({ classes }) {
  return <div className={classes.shortcuts}>
    {shortcuts.map(({ id, title, items }) => <div key={id}>
      <h3>{title}</h3>
      {items.map(({ id, name, commands }) => <div key={id} className={classes.shortcutsItems}>
        {name}
        <Commands commands={commands}/>
      </div>)}
    </div>)}
  </div>
}