import { useAuth } from '../../hooks';
import { toNiceDateTimeString } from "../../utils/date";


export default function DateTime({ value }) {
    const { user } = useAuth();

    if (user)
        return toNiceDateTimeString(value, user.tz_name)
}
