import { useState } from "react";
import { useMutation } from "@tanstack/react-query";

import { SettingsApi } from "../../../../services/api";
import { Modal } from "../../../../components";
import { ReactComponent as Delete } from "../../../../assets/delete.svg";
import styles from "./styles.module.scss";

export default function RootSettingsDeleteWCItemModal({
  onClose,
  isOpen,
  title,
  header,
  id,
  options,
  defaultOptionId,
  getDelta,
}) {
  const [replacement, setReplacement] = useState(defaultOptionId);

  const deleteMutation = useMutation({
    mutationFn: SettingsApi.SavePartialRoot,
    onSuccess(data) {
      onClose(data);
    },
  });

  const onDelete = () => {
    deleteMutation.mutate(getDelta({ replacement }));
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title={title}
      titleIcon={<Delete />}
      size={"medium"}
    >
      {options && header ? (
        <div className={styles.form}>
          <label>{header}</label>

          <select
            onChange={(e) => setReplacement(e.target.value)}
            value={replacement}
          >
            {options.map((o) => (
              <option key={o.id} value={o.id} disabled={o.id === id}>
                {o.name}
              </option>
            ))}
          </select>
        </div>
      ) : null}

      <div className={styles.buttons}>
        <button onClick={() => onClose()} className="button button-gray">
          Cancel
        </button>
        <button
          onClick={onDelete}
          disabled={deleteMutation.isLoading}
          className="button button-red"
        >
          Delete
        </button>
      </div>
    </Modal>
  );
}
