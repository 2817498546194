const options =  {
  0: {
    name: "At due date",
  },
  15: {
    name: "15 minutes before",
  },
  30: {
    name: "30 minutes before",
  },
  60: {
    name: "1 hour before",
  },
  120: {
    name: "2 hours before",
  },
  1140: {
    name: "1 day before",
  },
  2280: {
    name: "2 days before",
  },
}

export default options;