import { useMutation } from "@tanstack/react-query";
import { PulpApi } from "../../../../services/api";
import { ReactComponent as File } from "../../../../assets/file.svg";
import styles from "../pulp-block-file/pulp_block_file.module.scss";
import { useSessionId } from '../../../EditorSessionProvider';

const MAX_SIZE = 10 * 1024 * 1024;


export default function Draft({ onFileUploaded, blockId, pulpId, item }) {
  const sessionId = useSessionId();

  const uploadFileMutation = useMutation({
    mutationFn: PulpApi.UploadFile,
    onSuccess: ({ data }) => {
      onFileUploaded({ ...data, draft: false });
    },
  });

  const onSelectFile = (e) => {
    const file = e.target.files[0];

    if (file.size > MAX_SIZE) {
      return
    }

    const formData = new FormData();
    formData.append("file", file);

    uploadFileMutation.mutate({ blockId, pulpId, fileId: item.id, formData, sessionId });
  };

  let bottom = null;
  if (uploadFileMutation.isLoading) {
    bottom = <small>Uploading ...</small>
  } else if (uploadFileMutation.isError) {
    bottom = <small className="error-text">Can't upload</small>
  } else {
    bottom = <small>max 10 mb</small>
  }

  return (
    <label htmlFor="file" className={styles.uploadFile}>
      <input type="file" id="file" onChange={onSelectFile} className={styles.uploadFileInput} />
      <File />
      Upload File<br/>
      { bottom }
    </label>
  )
}
