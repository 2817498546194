import { $host } from "./host";

export class CommentsApi {
  static async For_(object_id, content_type) {
    const { data } = await $host.get(
      `/api/v1/comments/${object_id}/${content_type}/`
    );
    return data;
  }

  static async PostComment({ object_id, content_type, content, sessionId }) {
    const { data } = await $host.post(
      `/api/v1/comments/${object_id}/${content_type}/`,
      { content },
      { params: { sessionId } }
    );
    return data;
  }
}
