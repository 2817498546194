import {
  insertAt,
  findNodeAbove,
  findNodeBelow,
  moveItem,
  applyShiftLeft,
  applyShiftRight,
  newId,
  lexorank,
  strcmp,
  removeChild,
} from "./utils";

function EDIT_ITEM(state, action) {
  const { payload } = action;
  const { id, cursorPosition, anchorNode } = payload;

  const changeset = {};
  for (const s of Object.keys(payload)) {
    if (s in ["id", "cursorPosition"]) {
      continue;
    }
    changeset[s] = payload[s];
  }

  const itemEditBodyUpdate = {
    [id]: { ...state.items[id], ...changeset },
  };
  const itemsEditBodyUpdate = Object.assign(
    {},
    state.items,
    itemEditBodyUpdate
  );

  return Object.assign(
    {},
    state,
    { items: itemsEditBodyUpdate },
    { focus: { id, cursorPosition: cursorPosition || null, anchorNode } }
  );
}

function ADD_ITEM(state, action, internal__right_content) {
  const newItemsZone = [];

  const { payload } = action;
  const { id, cursorPosition } = payload;
  const originalItem = state.items[id];

  let right, left;

  if (internal__right_content !== void 0) {
    left = originalItem.content;
    right = internal__right_content;
  } else {
    left = originalItem.content.slice(0, cursorPosition);
    right = originalItem.content.slice(
      cursorPosition,
      originalItem.content.length
    );
  }

  const newItemTop = {
    ...originalItem,
    content: left,
  };

  const newItemBottom = {
    ...originalItem,
    comments: void 0,
    content: right,
    meta: {
      is_todo: originalItem.meta.is_todo,
    },
    id: newId(),
    parent:
      originalItem.id === state.root ? originalItem.id : originalItem.parent,
  };

  newItemsZone.push(newItemBottom.id);

  const itemsUpdate = {
    [newItemTop.id]: newItemTop,
    [newItemBottom.id]: newItemBottom,
  };

  const itemsOfItem = state.pulps[newItemBottom.parent];

  const updateChildpulps =
    originalItem.id !== state.root
      ? insertAt(itemsOfItem, id, newItemBottom.id, id === state.root ? 0 : 1)
      : [].concat([newItemBottom.id], itemsOfItem);

  let bottomRank = null;
  if (originalItem.id === state.root) {
    if (itemsOfItem[0]) {
      bottomRank = state.items[itemsOfItem[0]].rank;
    }
  } else {
    if (itemsOfItem[itemsOfItem.indexOf(newItemTop.id) + 1]) {
      bottomRank =
        state.items[itemsOfItem[itemsOfItem.indexOf(newItemTop.id) + 1]].rank;
    }
  }

  const [rank, ok] = lexorank.insert(newItemTop.rank, bottomRank);

  newItemBottom.rank = rank;

  const pulpsUpdate = Object.assign(
    {},
    state.pulps,
    { [newItemBottom.parent]: updateChildpulps },
    { [newItemBottom.id]: [] }
  );

  const blocksUpdate = Object.assign({}, state.blocks, {
    [newItemBottom.id]: [],
  });

  const newItems = Object.assign({}, state.items, itemsUpdate);

  let focusUpdate;
  if (action.cursorPosition === 0 && originalItem.content.length > 0) {
    focusUpdate = { focus: { id: id, cursorPosition: 0 } };
  } else {
    focusUpdate = { focus: { id: newItemBottom.id, cursorPosition: 0 } };
  }

  return Object.assign(
    {},
    state,
    { newItemsZone },
    { items: newItems },
    { pulps: pulpsUpdate },
    { blocks: blocksUpdate },
    focusUpdate
  );
}

function MOVE_FOCUS_UP(state, action) {
  const { payload } = action;
  const { id } = payload;
  const originalItem = state.items[id];

  // change the focus to the node that is visually immediately above the current node
  // 1. find the immediately preceding sibling of the current node:
  if (originalItem.parent) {
    const nearestRelative = findNodeAbove(state, id);
    return Object.assign({}, state, {
      focus: { id: nearestRelative, cursorPosition: 0 },
    });
  } else {
    return state;
  }
}

function MOVE_FOCUS_DOWN(state, action) {
  const { payload } = action;
  const { id } = payload;
  const originalItem = state.items[id];

  if (originalItem.parent) {
    const newFocus = findNodeBelow(state, id);
    if (newFocus) {
      return Object.assign({}, state, {
        focus: { id: newFocus, cursorPosition: 0 },
      });
    } else {
      return state;
    }
  } else {
    return state;
  }
}

function DELETE_BLOCK(state, action) {
  const { payload } = action;
  const { id } = payload;
  const originalItem = state.items[id];

  const itemsDeleteUpdate = Object.assign({}, state.items);
  delete itemsDeleteUpdate[id];

  let siblings = Object.assign([], state.blocks[originalItem.parent]);
  const ownIndex = siblings.indexOf(id);
  siblings.splice(ownIndex, 1);
  const blocksDeleteUpdate = Object.assign({}, state.blocks, {
    [originalItem.parent]: siblings,
  });

  const files = state.files[id] || [];
  for (const fileId in files) {
    delete itemsDeleteUpdate[fileId];
  }

  const reminders = state.reminders[id] || [];
  for (const reminderId in reminders) {
    delete itemsDeleteUpdate[reminderId];
  }

  const workItems = state.workItems[id] || [];
  for (const workItemId in workItems) {
    delete itemsDeleteUpdate[workItemId];
  }

  const filesDeleteUpdate = Object.assign({}, state.files);
  delete filesDeleteUpdate[id];

  const remindersDeleteUpdate = Object.assign({}, state.reminders);
  delete remindersDeleteUpdate[id];

  const workItemsDeleteUpdate = Object.assign({}, state.workItems);
  delete workItemsDeleteUpdate[id];

  return Object.assign(
    {},
    state,
    { items: itemsDeleteUpdate },
    { blocks: blocksDeleteUpdate },
    { files: filesDeleteUpdate },
    { reminders: remindersDeleteUpdate },
    { workItems: workItemsDeleteUpdate },
    { focus: {} }
  );
}

function DELETE_ITEM(state, action) {
  const { payload } = action;
  const { id } = payload;
  const originalItem = state.items[id];

  if (state.root === id) {
    return state;
  }

  let newFocus;
  const nodeAbove = findNodeAbove(state, id);
  let newCursorPosition = 0;
  if (state.items[nodeAbove].parent) {
    newFocus = nodeAbove;
    newCursorPosition = state.items[nodeAbove].content.length;
  } else {
    const nodeBelow = findNodeBelow(state, id);
    if (nodeBelow) {
      newFocus = nodeBelow;
      newCursorPosition = state.items[nodeBelow].content.length;
    } else {
      newFocus = state.root;
      newCursorPosition = state.items[state.root].content.length;
    }
  }

  // 1. check that the item has no children,
  // and that we have somewhere to put the cursor on after deletion
  if (state.pulps[id].length === 0 && newFocus) {
    // 2. delete item from the main dictionary:
    const itemsDeleteUpdate = Object.assign({}, state.items);
    delete itemsDeleteUpdate[id];
    // 3. delete item from the child array of the parrent:
    let siblings = Object.assign([], state.pulps[originalItem.parent]);
    const ownIndex = siblings.indexOf(id);
    siblings.splice(ownIndex, 1); // update siblings
    const pulpsDeleteUpdate = Object.assign({}, state.pulps, {
      [originalItem.parent]: siblings,
    });

    return Object.assign(
      {},
      state,
      { items: itemsDeleteUpdate },
      { pulps: pulpsDeleteUpdate },
      { focus: { id: newFocus, cursorPosition: newCursorPosition } }
    );
  } else {
    // nothing to do:
    return state;
  }
}

function ADD_BLOCK(state, action) {
  const newItemsZone = [];

  const { payload } = action;
  const { id, tag } = payload;
  const originalItem = state.items[id];

  const newBlock = {
    id: newId(),
    type: "block",
    tag: tag || "p",
    content: "",
    parent:
      originalItem.type === "pulp" ? originalItem.id : originalItem.parent,
  };

  newItemsZone.push(newBlock.id);

  ///

  let filesUpdate = null,
    workItemsUpdate = null,
    remindersUpdate = null,
    focusUpdate = { id: newBlock.id, cursorPosition: 0 },
    itemsUpdate = {
      [newBlock.id]: newBlock,
    };

  if (newBlock.tag === "file") {
    const newFile = {
      id: newId(),
      parent: id,
      draft: true,
      type: "file",
    };

    itemsUpdate[newFile.id] = newFile;

    filesUpdate = Object.assign({}, state.files, {
      [newBlock.id]: [newFile.id],
    });
  } else if (tag === "work-item") {
    const newWorkItem = {
      id: newId(),
      parent: id,
      draft: true,
      type: "work-item",
      content: "",
      body: "",
    };

    itemsUpdate[newWorkItem.id] = newWorkItem;

    workItemsUpdate = Object.assign({}, state.workItems, {
      [newBlock.id]: [newWorkItem.id],
    });

    focusUpdate = { id: newWorkItem.id, cursorPosition: 0 };
  } else if (tag === "reminder") {
    const reminder = {
      id: newId(),
      parent: id,
      draft: true,
      type: "reminder",
      content: "",
      alert_relative_value: null,
      alert_absolute_value: null,
      alert_is_relative: true,
      date: new Date(),
    };

    itemsUpdate[reminder.id] = reminder;

    remindersUpdate = Object.assign({}, state.reminders, {
      [newBlock.id]: [reminder.id],
    });

    focusUpdate = { id: reminder.id, cursorPosition: 0 };
  }

  ////

  const blocksUpdate = Object.assign({}, state.blocks, {
    [newBlock.parent]:
      originalItem.type === "pulp"
        ? [...state.blocks[newBlock.parent], newBlock.id]
        : insertAt(state.blocks[newBlock.parent], id, newBlock.id, 1),
  });

  const newItems = Object.assign({}, state.items, itemsUpdate);

  return Object.assign(
    {},
    state,
    { newItemsZone },
    { items: newItems },
    { blocks: blocksUpdate },
    { focus: focusUpdate },
    filesUpdate ? { files: filesUpdate } : {},
    workItemsUpdate ? { workItems: workItemsUpdate } : {},
    remindersUpdate ? { reminders: remindersUpdate } : {}
  );
}

function CONVERT_BLOCK(state, action) {
  const { payload } = action;
  const { id, tag } = payload;

  const ownItem = state.items[id];

  const itemUpdate = {
    [id]: { ...state.items[id], tag: tag, content: "" },
  };

  let filesUpdate = null,
    workItemsUpdate = null,
    remindersUpdate = null,
    focusUpdate = {};

  /*if (tag === 'reminder') {
    const newFile = {
      id: newId(),
      parent: id,
      draft: true,
      type: 'file',
    }

    itemUpdate[newFile.id] = newFile

    filesUpdate = Object.assign({}, state.files, {
      [id]: [newFile.id],
    })
  }*/

  if (tag === "work-item") {
    const newWorkItem = {
      id: newId(),
      parent: id,
      draft: true,
      type: "work-item",
      content: ownItem.content,
      body: "",
    };

    itemUpdate[newWorkItem.id] = newWorkItem;

    workItemsUpdate = Object.assign({}, state.workItems, {
      [id]: [newWorkItem.id],
    });

    focusUpdate = { id: newWorkItem.id, cursorPosition: 0 };
  }

  if (tag === "reminder") {
    const reminder = {
      id: newId(),
      parent: id,
      draft: true,
      type: "reminder",
      content: ownItem.content,
      alert_relative_value: null,
      alert_absolute_value: null,
      alert_is_relative: true,
      date: new Date(),
    };

    itemUpdate[reminder.id] = reminder;

    remindersUpdate = Object.assign({}, state.reminders, {
      [id]: [reminder.id],
    });

    focusUpdate = { id: reminder.id, cursorPosition: 0 };
  }

  const itemsEditBodyUpdate = Object.assign({}, state.items, itemUpdate);

  return Object.assign(
    {},
    state,
    { items: itemsEditBodyUpdate },
    filesUpdate ? { files: filesUpdate } : {},
    workItemsUpdate ? { workItems: workItemsUpdate } : {},
    remindersUpdate ? { reminders: remindersUpdate } : {},
    { focus: focusUpdate }
  );
}

function ADD_FILE_TO_BLOCK(state, action) {
  const { payload } = action;
  const { id } = payload;

  const newFile = {
    id: newId(),
    parent: id,
    draft: true,
    type: "file",
  };

  const itemsUpdate = {
    [newFile.id]: newFile,
  };

  const filesUpdate = Object.assign({}, state.files, {
    [id]: [...(state.files[id] || []), newFile.id],
  });

  const newItems = Object.assign({}, state.items, itemsUpdate);

  return Object.assign({}, state, { items: newItems }, { files: filesUpdate });
}

function INIT_SUBTREE(state, action) {
  let newItems = Object.assign({}, state.items);
  let newPulps = Object.assign({}, state.pulps);
  let newBlocks = Object.assign({}, state.blocks);
  let newFiles = Object.assign({}, state.files);
  let newWorkItems = Object.assign({}, state.workItems);
  let newReminders = Object.assign({}, state.reminders);

  function deleteItemBlocks(ownItem) {
    const itemBlocks = newBlocks[ownItem.id] || [];
    itemBlocks.forEach((id) => {
      deleteBlock(newItems[id]);
    });
    delete newBlocks[ownItem.id];
  }

  function deleteItemSubtree(ownItem) {
    let children = newPulps[ownItem.id] || [];
    for (const childId of children) {
      deleteItemSubtree(newPulps[childId]);
    }

    deleteItemBlocks(ownItem);
    delete newItems[ownItem.id];

    if (newPulps[ownItem.parent]) {
      let siblings = newPulps[ownItem.parent];
      const ownIndex = siblings.indexOf(ownItem.id);
      siblings.splice(ownIndex, 1);
      newPulps[ownItem.parent] = siblings;
    }
  }

  function deleteBlock(ownItem) {
    let siblings = newBlocks[ownItem.parent];
    if (siblings) {
      const ownIndex = siblings.indexOf(ownItem.id);
      siblings.splice(ownIndex, 1);
      newBlocks[ownIndex.parent] = siblings;
    }

    delete newItems[ownItem.id];

    const files = newFiles[ownItem.id] || [];

    for (const fileId of files) {
      delete newItems[fileId];
    }

    const reminders = newReminders[ownItem.id] || [];
    for (const reminderId of reminders) {
      delete newItems[reminderId];
    }

    const workItems = newWorkItems[ownItem.id] || [];
    for (const workItemId of workItems) {
      delete newItems[workItemId];
    }

    delete newFiles[ownItem.id];
    delete newReminders[ownItem.id];
    delete newWorkItems[ownItem.id];
  }

  action.payload.deletes
    .map((obj) => {
      return {
        ...obj,
        deleted_at: new Date(obj.deleted_at),
      };
    })
    .sort((a, b) => a - b)
    .filter((obj) => !!newItems[obj.key])
    .forEach((obj) => {
      const ownItem = newItems[obj.key];
      if (!ownItem) {
        return;
      }
      if (ownItem.type === "pulp") {
        deleteItemSubtree(ownItem);
      } else if (ownItem.type === "block") {
        deleteBlock(ownItem);
      } else if (ownItem.type === "file") {
        delete newItems[ownItem.id];

        let siblings = newFiles[ownItem.parent];
        if (siblings) {
          const ownIndex = siblings.indexOf(ownItem.id);
          siblings.splice(ownIndex, 1);
          newFiles[ownItem.parent] = siblings;
        }
      }
    });

  for (const item of action.payload.tree) {
    const ownItem = state.items[item.id];

    newItems[item.id] = item;

    if (item.parent && item.type === "pulp") {
      if (ownItem && item.parent !== ownItem.parent) {
        newPulps[ownItem.parent] = removeChild(
          newPulps,
          ownItem.parent,
          ownItem.id
        );
      }

      const pulps = newPulps[item.parent].map((id) => newItems[id]);

      if (pulps.find((i) => i.id === item.id) === void 0) {
        pulps.push(item);
      }

      newPulps[item.parent] = pulps
        .sort((a, b) => strcmp(a.rank, b.rank))
        .map((a) => a.id);

      newPulps[item.id] = newPulps[item.id] || [];
      newBlocks[item.id] = newBlocks[item.id] || [];
    } else if (item.type === "block") {
      const blocks = newBlocks[item.parent] || [];
      if (blocks.indexOf(item.id) === -1) {
        newBlocks[item.parent] = [item.id].concat(blocks);
      }
      if (item.tag === "work-item") {
        newWorkItems[item.id] = newWorkItems[item.id] || [];
      } else if (item.tag === "reminder") {
        newReminders[item.id] = newReminders[item.id] || [];
      } else if (item.tag === "file") {
        newFiles[item.id] = newFiles[item.id] || [];
      }
    } else if (item.type === "file") {
      const files = newFiles[item.parent] || [];
      if (files.indexOf(item.id) === -1) {
        newFiles[item.parent] = [item.id].concat(files);
      }
    } else if (item.type === "work-item") {
      const workItems = newWorkItems[item.parent] || [];
      if (workItems.indexOf(item.id) === -1) {
        newWorkItems[item.parent] = [item.id].concat(workItems);
      }
    } else if (item.type === "reminder") {
      const reminders = newReminders[item.parent] || [];
      if (reminders.indexOf(item.id) === -1) {
        newReminders[item.parent] = [item.id].concat(reminders);
      }
    }
  }

  const workFlowUpdates = {
    workFlow: state.workFlow,
  };
  if (
    action.payload.workFlow &&
    action.payload.workFlow.version !== state.workFlow.version
  ) {
    workFlowUpdates["workFlow"] = action.payload.workFlow;
  }

  return Object.assign(
    {},
    state,
    workFlowUpdates,
    { items: newItems },
    { pulps: newPulps },
    { blocks: newBlocks },
    { files: newFiles },
    { workItems: newWorkItems },
    { reminders: newReminders }
  );
}

function INIT(state, action) {
  const localHash = window.location.hash
    ? window.location.hash.split("#")[1]
    : null;

  const { data, key } = action.payload;

  const newState = {
    items: {},
    pulps: {},
    blocks: {},
    files: {},
    workItems: {},
    reminders: {},
    focus: {},
    workFlow: data.workFlow,
    root: localHash || key,
    newItemsZone: [],
  };

  for (const item of data.tree) {
    newState.items[item.id] = item;
    newState.pulps[item.id] = newState.pulps[item.id] || [];
    newState.blocks[item.id] = newState.blocks[item.id] || [];

    if (item.parent && item.type === "pulp") {
      const pulps = (newState.pulps[item.parent] =
        newState.pulps[item.parent] || []);
      pulps.push(item);
    } else if (item.type === "block") {
      const blocks = (newState.blocks[item.parent] =
        newState.blocks[item.parent] || []);
      blocks.push(item.id);
    } else if (item.type === "file") {
      const files = (newState.files[item.parent] =
        newState.files[item.parent] || []);
      files.push(item.id);
    } else if (item.type === "work-item") {
      const workItems = (newState.workItems[item.parent] =
        newState.workItems[item.parent] || []);
      workItems.push(item.id);
    } else if (item.type === "reminder") {
      const reminders = (newState.reminders[item.parent] =
        newState.reminders[item.parent] || []);
      reminders.push(item.id);
    }
  }

  for (const id of Object.keys(newState.pulps)) {
    const children = newState.pulps[id];
    newState.pulps[id] = children
      .sort((a, b) => strcmp(a.rank, b.rank))
      .map((a) => a.id);
  }

  for (const pulp_id of Object.keys(newState.blocks)) {
    for (const block_id of newState.blocks[pulp_id]) {
      const block = newState.items[block_id];
      if (block.tag === "work-item" && !newState.workItems[block.id]) {
        const newWorkItem = {
          id: newId(),
          parent: block.id,
          draft: true,
          type: "work-item",
          content: "",
          body: "",
        };

        newState.items[newWorkItem.id] = newWorkItem;
        newState.workItems[block.id] = [newWorkItem.id];
      } else if (block.tag === "reminder" && !newState.reminders[block.id]) {
        const newReminder = {
          id: newId(),
          parent: block.id,
          draft: true,
          type: "reminder",
          content: "",
          alert_relative_value: null,
          alert_absolute_value: null,
          alert_is_relative: true,
          date: new Date(),
        };

        newState.items[newReminder.id] = newReminder;
        newState.reminders[block.id] = [newReminder.id];
      }
    }
  }

  return newState;
}

function DELETE_FILE(state, action) {
  const { payload } = action;
  const { id } = payload;

  const originalItem = state.items[id];

  const itemsDeleteUpdate = Object.assign({}, state.items);
  delete itemsDeleteUpdate[id];

  let siblings = Object.assign([], state.files[originalItem.parent]);
  const ownIndex = siblings.indexOf(id);
  siblings.splice(ownIndex, 1);
  const filesDeleteUpdate = Object.assign({}, state.files, {
    [originalItem.parent]: siblings,
  });

  return Object.assign(
    {},
    state,
    { items: itemsDeleteUpdate },
    { files: filesDeleteUpdate },
    { focus: {} }
  );
}

function OUTDENT_BLOCK(state, action) {
  const { payload } = action;
  const { id } = payload;

  function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  const originalItem = state.items[id];
  const content = originalItem.content;

  state = ADD_ITEM(
    state,
    { payload: { id: originalItem.parent, cursorPosition: content.length } },
    stripHtml(content)
  );

  const itemsDeleteUpdate = Object.assign({}, state.items);
  delete itemsDeleteUpdate[id];

  let siblings = Object.assign([], state.blocks[originalItem.parent]);
  const ownIndex = siblings.indexOf(id);
  siblings.splice(ownIndex, 1);
  const blocksDeleteUpdate = Object.assign({}, state.blocks, {
    [originalItem.parent]: siblings,
  });

  return Object.assign(
    {},
    state,
    { items: itemsDeleteUpdate },
    { blocks: blocksDeleteUpdate }
  );
}

export default function reducer(state, action) {
  console.log(action);

  const { type, payload } = action;

  switch (type) {
    case "INIT":
      return INIT(state, action);
    case "INIT_SUBTREE":
      return Object.assign({}, state, INIT_SUBTREE(state, action), {
        newItemsZone: [],
      });
    case "EDIT_ITEM":
      return Object.assign({}, state, EDIT_ITEM(state, action), {
        newItemsZone: [],
      });
    case "ADD_ITEM":
      return ADD_ITEM(state, action);
    case "SHIFT_ITEM_LEFT":
      return Object.assign({}, state, applyShiftLeft(state, payload), {
        newItemsZone: [],
      });
    case "SHIFT_ITEM_RIGHT":
      return Object.assign({}, state, applyShiftRight(state, payload), {
        newItemsZone: [],
      });
    case "MOVE_FOCUS_UP":
      return Object.assign({}, state, MOVE_FOCUS_UP(state, action), {
        newItemsZone: [],
      });
    case "MOVE_FOCUS_DOWN":
      return Object.assign({}, state, MOVE_FOCUS_DOWN(state, action), {
        newItemsZone: [],
      });
    case "CHANGE_FOCUS":
      return Object.assign(
        {},
        state,
        {
          focus: { id: payload.id, cursorPosition: payload.cursorPosition },
        },
        { newItemsZone: [] }
      );
    case "CLEAR_FOCUS":
      return Object.assign(
        {},
        state,
        {
          focus: {},
        },
        { newItemsZone: [] }
      );
    case "CHANGE_ROOT":
      return Object.assign(
        {},
        state,
        { root: payload.id },
        { focus: { id: payload.id, cursorPosition: 0 } },
        { newItemsZone: [] }
      );
    case "MOVE_ITEM":
      return Object.assign(
        {},
        state,
        moveItem(state, payload.id, payload.offset),
        { newItemsZone: [] }
      );
    case "DELETE_ITEM":
      return Object.assign({}, state, DELETE_ITEM(state, action), {
        newItemsZone: [],
      });
    case "DELETE_BLOCK":
      return Object.assign({}, state, DELETE_BLOCK(state, action), {
        newItemsZone: [],
      });
    case "ADD_BLOCK":
      return ADD_BLOCK(state, action);
    case "CONVERT_BLOCK":
      return Object.assign({}, state, CONVERT_BLOCK(state, action), {
        newItemsZone: [],
      });
    case "ADD_FILE_TO_BLOCK":
      return Object.assign({}, state, ADD_FILE_TO_BLOCK(state, action), {
        newItemsZone: [],
      });
    case "DELETE_FILE":
      return Object.assign({}, state, DELETE_FILE(state, action), {
        newItemsZone: [],
      });
    case "OUTDENT_BLOCK":
      return OUTDENT_BLOCK(state, action);
    default:
      throw new Error();
  }
}

const initialValue = {
  items: {},
  pulps: {},
  blocks: {},
  files: {},
  workItems: {},
  reminders: {},
  focus: {},
  workFlow: {},
  root: null,
  newItemsZone: [],
};

export { initialValue };
