import { $host } from "./host";

export class PulpApi {
  static async GetTree(params) {
    const { data } = await $host.get(`/api/v1/pulp/tree/`, { params });
    return data;
  }

  static async SyncPulp(to_sync) {
    const { data } = await $host.post("/api/v1/pulp/sync/", to_sync);
    return data;
  }

  static async Roots(access = "viewer") {
    const { data } = await $host.get("/api/v1/pulp/roots/", {
      params: { access },
    });
    return data;
  }

  static async Root(key) {
    const { data } = await $host.get(`/api/v1/pulp/roots/${key}/`);
    return data;
  }

  static async CreateRoot(root) {
    return await $host.post("/api/v1/pulp/roots/", root);
  }

  static async DeleteRoot({ id }) {
    return await $host.delete(`/api/v1/pulp/roots/${id}/`);
  }

  static async UploadFile({ pulpId, blockId, fileId, formData, sessionId }) {
    return await $host.post(
      `/api/v1/blocks/${pulpId}/${blockId}/files/${fileId}/`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
        params: { sessionId },
      }
    );
  }

  static async DeleteFile({ pulpId, blockId, fileId, sessionId }) {
    return await $host.delete(
      `/api/v1/blocks/${pulpId}/${blockId}/files/${fileId}/`, { params: { sessionId } }
    );
  }

  static async CreateWorkItem({
    pulpId,
    blockId,
    workItemId,
    content,
    workItemType,
    sessionId,
  }) {
    return await $host.post(
      `/api/v1/blocks/${pulpId}/${blockId}/work-items/${workItemId}/`,
      { content, workItemType },
      { params: { sessionId } }
    );
  }

  static async CreateReminder({
    pulpId,
    blockId,
    reminderId,
    content,
    date,
    alert_relative_value,
    alert_absolute_value,
    alert_is_relative,
    sessionId,
  }) {
    return await $host.post(
      `/api/v1/blocks/${pulpId}/${blockId}/reminders/${reminderId}/`,
      {
        content,
        date,
        alert_relative_value,
        alert_absolute_value,
        alert_is_relative,
      },
      { params: { sessionId } }
    );
  }
}
