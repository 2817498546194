import { useMemo, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { ApiError } from "../components";
import { WorkItemModal } from "./PulpView/components";
import { useSessionId } from "./EditorSessionProvider";

import { WorkItemsApi } from "../services/api";
import { workItemsKeys } from "../constants/queryKeys";

import { renderFiles } from "./PulpView/PulpView";
import pulpReducer, {
  initialValue as pulpInitialValue,
} from "./PulpView/reducer";

export default function WorkItemView() {
  const { workItemId, rootId } = useParams();
  const sessionId = useSessionId();

  const [pulp, pulpDispatch] = useReducer(pulpReducer, pulpInitialValue);

  const activeWorkItem = useMemo(() => {
    return pulp.items[workItemId]
  }, [pulp, workItemId]);

  const detailsSubtreeQuery = useQuery({
    queryFn: () => WorkItemsApi.DetailsSubtree({ id: workItemId, sessionId, }),
    queryKey: workItemsKeys.detailsSubtee(workItemId),
    onSuccess(data) {
      pulpDispatch({ type: "INIT", payload: { data: data, key: rootId} });
    },
  });

  function noop() {}

  if (detailsSubtreeQuery.isLoading) {
    return null;
  } else if (detailsSubtreeQuery.isError) {
    return <ApiError error={detailsSubtreeQuery.error} />
  }

  if (!activeWorkItem) {
    return
  }

  return (
    <WorkItemModal
      root={rootId}
      isOpen={true}
      item={activeWorkItem}
      workFlowConfig={pulp.workFlow}
      onClose={noop}
      onWorkItemUpdated={(changset) => {
        pulpDispatch({
          type: "EDIT_ITEM",
          payload: {
            id: activeWorkItem,
            ...changset,
          },
        });
      }}
      files={renderFiles(
        pulp,
        activeWorkItem.parent,
        pulp.items[activeWorkItem.parent].parent,
        pulpDispatch
      )}
    />
  );
}
