import { useReducer, useState, useEffect, useContext } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams, useOutletContext } from "react-router-dom";

import {
  PulpTree,
  PulpBranch,
  PulpBranchContent,
  PulpBlock,
  PulpBlockP,
  PulpBlockWorkItem,
  PulpBlockReminder,
  PulpBlockFile,
  PulpFile,
  PulpWorkItem,
  PulpReminder,
  WorkItemModal,
  ThreadAside,
} from "./components";

import { PulpBreadcrumbsContext } from "../../contexts";

import { useInterval, useAvatarFeedback } from "../../hooks";
import { PulpApi } from "../../services/api";
import { pulpApiQueryKeys } from "../../constants/queryKeys";
import Toolbar from "../../components/toolbar/Toolbar";
import { useSessionId, clientState } from "../EditorSessionProvider";

import reducer, { initialValue } from "./reducer";
import { newId } from "./reducer/utils";

function dirtyReducer(state, action) {
  switch (action.type) {
    case "MARK_DIRTY":
      return Object.assign({}, state, {
        dirtyQueue: { ...state.dirtyQueue, ...action.payload },
      });
    case "OUTGOING_OK":
      return Object.assign({}, state, {
        outgoingQueue: {},
        consecutiveFailedAttempts: 0,
      });
    case "OUTGOING_FAILED":
      return Object.assign({}, state, {
        dirtyQueue: {
          ...state.outgoingQueue,
          ...state.dirtyQueue,
        },
        consecutiveFailedAttempts: state.consecutiveFailedAttempts + 1,
        outgoingQueue: {},
      });
    case "OUTGOING_IN_PROGRESS":
      return Object.assign({}, state, {
        dirtyQueue: {},
        outgoingQueue: { ...state.dirtyQueue },
      });
    default:
      return state;
  }
}

const dirtyReducerInitialValue = {
  consecutiveFailedAttempts: 0,
  dirtyQueue: {},
  outgoingQueue: {},
};

const renderFiles = (state, childId, id, dispatch, ignore_if_empty) => {
  const files = state.files[childId];
  if (!files && ignore_if_empty === true) {
    return;
  }

  return (
    <PulpBlockFile
      onAddFile={() => {
        dispatch({
          type: "ADD_FILE_TO_BLOCK",
          payload: {
            id: childId,
          },
        });
      }}
    >
      {files &&
        files.map((fileId) => (
          <PulpFile
            key={fileId}
            pulpId={id}
            blockId={childId}
            item={state.items[fileId]}
            onDeleteFile={() => {
              dispatch({
                type: "DELETE_FILE",
                payload: {
                  id: fileId,
                },
              });
            }}
            onFileUploaded={(data) => {
              dispatch({
                type: "EDIT_ITEM",
                payload: {
                  id: fileId,
                  ...data,
                },
              });
            }}
          />
        ))}
    </PulpBlockFile>
  );
};

export default function ReadOnlyPulpView() {
  const { key } = useParams();
  const { isSidebarOpen } = useOutletContext();
  const avatarFeedback = useAvatarFeedback();

  const editorSessionId = useSessionId();

  const { setBreadcrumbs } = useContext(PulpBreadcrumbsContext);

  const [activeWorkItem, setActiveWorkItem] = useState(null);
  const [activeThreadItem, setActiveThreadItem] = useState(null);

  const [state, _dispatch] = useReducer(reducer, initialValue);
  const [dirty, dispatchDirty] = useReducer(
    dirtyReducer,
    dirtyReducerInitialValue
  );

  useEffect(() => {
    for (const id of state.newItemsZone)
      dispatchDirty({
        type: "MARK_DIRTY",
        payload: {
          [id]: {
            ts: +new Date() + state.newItemsZone.indexOf(id),
          },
        },
      });
  }, [state]);

  const hashChangeHandler = () => {
    const id = window.location.hash.split("#")[1];

    _dispatch({
      type: "CHANGE_ROOT",
      payload: {
        id,
      },
    });
  };

  useEffect(() => {
    window.addEventListener("hashchange", hashChangeHandler);
    return () => {
      window.removeEventListener("hashchange", hashChangeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = (action) => {
    _dispatch(action);
    if (action.payload.id) {
      dispatchDirty({
        type: "MARK_DIRTY",
        payload: {
          [action.payload.id]: {
            ts: +new Date(),
            item: { ...state.items[action.payload.id] },
          },
        },
      });
    }
  };

  const syncPulpMutation = useMutation(PulpApi.SyncPulp, {
    onSuccess(data) {
      _dispatch({ type: "INIT_SUBTREE", payload: data });
      dispatchDirty({ type: "OUTGOING_OK" });
      avatarFeedback.reset();
    },
    onError() {
      dispatchDirty({ type: "OUTGOING_FAILED" });
      avatarFeedback.indicateError();
    },
    onMutate() {
      dispatchDirty({ type: "OUTGOING_IN_PROGRESS" });
      avatarFeedback.indicateLoading();
    },
  });

  useInterval(
    () => {
      if (Object.keys(dirty.dirtyQueue).length === 0) {
        return;
      }

      const to_sync = Object.keys(dirty.dirtyQueue).map((id) => {
        const item = state.items[id];
        if (!item) {
          return {
            id: id,
            status: "deleted",
            type: dirty.dirtyQueue[id].item.type === "block" ? "block" : "pulp",
            parent: dirty.dirtyQueue[id].item.parent,
            ts: dirty.dirtyQueue[id].ts,
          };
        }
        return {
          ...{ ...item, cursorPosition: undefined, anchorNode: undefined },
          status: "updated",
          ts: dirty.dirtyQueue[id].ts,
        };
      });

      syncPulpMutation.mutate({
        key,
        to_sync: to_sync.filter(i => i.type !== 'work-item'),
        transaction_id: newId(),
        session_id: editorSessionId,
        client_state: clientState(),
      });
    },
    syncPulpMutation.isLoading || dirty.consecutiveFailedAttempts > 5
      ? null
      : 1000
  );

  useInterval(
    () => {
      syncPulpMutation.mutate({
        key,
        to_sync: [],
        transaction_id: newId(),
        session_id: editorSessionId,
        client_state: clientState(),
      });
    },
    syncPulpMutation.isLoading || dirty.consecutiveFailedAttempts > 5
      ? null
      : 10000
  );

  const handleBlockCommand = (id, command) => {
    if (command.tag === "delete") {
      dispatch({
        type: "DELETE_BLOCK",
        payload: {
          id,
        },
      });
    } else if (command.tag === "show-thread") {
      setActiveThreadItem(state.items[id]);
    } else if (command.tag === "outdent-block") {
      dispatch({
        type: "OUTDENT_BLOCK",
        payload: {
          id,
        },
      });
    } else {
      dispatch({
        type: "CONVERT_BLOCK",
        payload: {
          id,
          tag: command.tag,
        },
      });
    }
  };

  const handlePulpCommand = (id, command) => {
    if (
      command.tag === "add-text-block" ||
      command.tag === "add-file-block" ||
      command.tag === "add-work-block" ||
      command.tag === "add-reminder-block"
    ) {
      _dispatch({
        type: "ADD_BLOCK",
        payload: {
          id: id,
          ...command.payload,
        },
      });
    } else if (
      command.tag === "remove-checkbox" ||
      command.tag === "add-checkbox"
    ) {
      dispatch({
        type: "EDIT_ITEM",
        payload: {
          id: id,
          meta: {
            ...state.items[id].meta,
            is_todo: command.tag !== "remove-checkbox",
          },
        },
      });
    } else if (command.tag === "toogle-checked") {
      dispatch({
        type: "EDIT_ITEM",
        payload: {
          id: id,
          meta: {
            ...state.items[id].meta,
            is_checked: command.payload.is_checked,
          },
        },
      });
    } else if (command.tag === "show-thread") {
      setActiveThreadItem(state.items[id]);
    } else if (command.tag === "toogle-collapsed") {
      dispatch({
        type: "EDIT_ITEM",
        payload: {
          id: id,
          meta: {
            ...state.items[id].meta,
            is_collapsed: !state.items[id].meta.is_collapsed,
          },
        },
      });
    } else if (command.tag === "delete") {
      dispatch({
        type: "DELETE_ITEM",
        payload: {
          id: id,
        },
      });
    } else if (command.tag === "move") {
      dispatch({
        type: "MOVE_ITEM",
        payload: {
          id,
          ...command.payload,
        },
      });
    } else if (command.tag === "indent") {
      dispatch({
        type:
          command.payload.dir === "left"
            ? "SHIFT_ITEM_LEFT"
            : "SHIFT_ITEM_RIGHT",
        payload: {
          cursorPosition: window.getSelection().anchorOffset,
          id,
        },
      });
    }
  };

  const updateAndAddBlock = (id, data) => {
    _dispatch({
      type: "EDIT_ITEM",
      payload: {
        id: id,
        ...data,
      },
    });

    _dispatch({
      type: "ADD_BLOCK",
      payload: {
        id: state.items[id].parent,
        tag: "p",
      },
    });
  };

  const pulpTree = (id) => {
    if (!id) return;

    const item = state.items[id];
    const children = state.pulps[id];
    const blocks = state.blocks[id] || [];

    const branchChildren =
      children && !item.meta.is_collapsed
        ? children.map((childId) => pulpTree(childId))
        : [];

    return (
      <PulpBranch
        key={id}
        isRoot={id === state.root}
        item={item}
        onCommand={(command) => handlePulpCommand(id, command)}
        hasChildren={children.length > 0}
        hasBlocks={blocks.length > 0}
      >
        <PulpBranchContent
          cursorPosition={
            state.focus.id === id ? state.focus.cursorPosition : null
          }
          onAddNewPulp={() => {
            dispatch({
              type: "ADD_ITEM",
              payload: {
                cursorPosition: window.getSelection().anchorOffset,
                id,
              },
            });
          }}
          onAddNewBlock={(tag) => {
            dispatch({
              type: "ADD_BLOCK",
              payload: {
                id,
                tag,
              },
            });
          }}
          onEdit={(changeset) => {
            dispatch({
              type: "EDIT_ITEM",
              payload: {
                cursorPosition: window.getSelection().anchorOffset,
                id,
                ...changeset,
              },
            });
          }}
          onMove={(offset) => {
            dispatch({
              type: "MOVE_ITEM",
              payload: {
                id,
                offset,
              },
            });
          }}
          onTab={(dir) => {
            dispatch({
              type: dir === "left" ? "SHIFT_ITEM_LEFT" : "SHIFT_ITEM_RIGHT",
              payload: {
                cursorPosition: window.getSelection().anchorOffset,
                id,
              },
            });
          }}
          onArrow={(dir) => {
            _dispatch({
              type: dir === "up" ? "MOVE_FOCUS_UP" : "MOVE_FOCUS_DOWN",
              payload: {
                id,
              },
            });
          }}
          onDeleteItem={() => {
            dispatch({
              type: "DELETE_ITEM",
              payload: {
                id,
              },
            });
          }}
          item={item}
        />
        {blocks &&
          blocks.map((childId) => {
            const block = state.items[childId];
            switch (block.tag) {
              case "p":
                return (
                  <PulpBlock
                    key={childId}
                    item={block}
                    onCommand={(command) =>
                      handleBlockCommand(childId, command)
                    }
                  >
                    <PulpBlockP
                      focus={state.focus.id === childId ? state.focus : null}
                      item={block}
                      onEdit={(changeset) => {
                        dispatch({
                          type: "EDIT_ITEM",
                          payload: {
                            id: childId,
                            ...changeset,
                          },
                        });
                      }}
                      onFocusLost={() =>
                        dispatch({
                          type: "CLEAR_FOCUS",
                          payload: {},
                        })
                      }
                      onAddNewBlock={(tag) => {
                        dispatch({
                          type: "ADD_BLOCK",
                          payload: {
                            id: childId,
                            tag,
                          },
                        });
                      }}
                      onCommand={(command) =>
                        handleBlockCommand(childId, command)
                      }
                      onDeleteBlock={() => {
                        dispatch({
                          type: "DELETE_BLOCK",
                          payload: {
                            id: childId,
                          },
                        });
                      }}
                    />
                  </PulpBlock>
                );
              case "work-item":
                const workItemId = state.workItems[childId][0];
                if (!workItemId) return null;
                return (
                  <PulpBlock
                    item={state.items[workItemId]}
                    key={childId}
                    onCommand={(command) => {
                      if (command.tag === "show-thread") {
                        setActiveThreadItem(state.items[workItemId]);
                      } else {
                        handleBlockCommand(childId, command)
                      }
                    }}
                  >
                    <PulpBlockWorkItem
                      onDeleteBlock={() => {
                        dispatch({
                          type: "DELETE_BLOCK",
                          payload: {
                            id: childId,
                          },
                        });
                      }}
                    >
                      <PulpWorkItem
                        cursorPosition={
                          state.focus.id === workItemId
                            ? state.focus.cursorPosition
                            : null
                        }
                        item={state.items[workItemId]}
                        workFlowConfig={state.workFlow}
                        pulpId={id}
                        blockId={childId}
                        onWorkItemCreated={(data) =>
                          updateAndAddBlock(workItemId, data)
                        }
                        onClick={(_) =>
                          setActiveWorkItem(state.items[workItemId])
                        }
                      />
                      {state.items[workItemId].draft
                        ? null
                        : renderFiles(state, childId, id, _dispatch, true)}
                    </PulpBlockWorkItem>
                  </PulpBlock>
                );
              case "reminder":
                const reminderId = state.reminders[childId][0];
                if (!reminderId) return null;
                return (
                  <PulpBlock
                    item={state.items[childId]}
                    key={childId}
                    onCommand={(command) =>
                      handleBlockCommand(childId, command)
                    }
                  >
                    <PulpBlockReminder
                      onDeleteBlock={() => {
                        dispatch({
                          type: "DELETE_BLOCK",
                          payload: {
                            id: childId,
                          },
                        });
                      }}
                    >
                      <PulpReminder
                        item={state.items[reminderId]}
                        pulpId={id}
                        blockId={childId}
                        onReminderCreated={(data) =>
                          updateAndAddBlock(reminderId, data)
                        }
                      />
                      {renderFiles(state, childId, id, _dispatch, true)}
                    </PulpBlockReminder>
                  </PulpBlock>
                );
              case "file":
                return (
                  <PulpBlock
                    key={childId}
                    item={state.items[childId]}
                    onCommand={(command) =>
                      handleBlockCommand(childId, command)
                    }
                  >
                    {renderFiles(state, childId, id, _dispatch)}
                  </PulpBlock>
                );
              default:
                return null;
            }
          })}
        {branchChildren}
      </PulpBranch>
    );
  };

  const { isLoading, isError } = useQuery(
    pulpApiQueryKeys.tree(key),
    () => PulpApi.GetTree({ key, session_id: editorSessionId }),
    {
      onSuccess(data) {
        _dispatch({ type: "INIT", payload: { data, key } });
      },
    }
  );

  useEffect(() => {
    if (state.root) {
      let currentNode = state.root;
      let entries = [];

      while (state.items[currentNode].parent) {
        currentNode = state.items[currentNode].parent;
        const item = state.items[currentNode];
        entries.unshift({
          id: item.id,
          name: item.content,
        });
      }

      if (entries.length === 0) {
        entries.unshift({
          id: state.items[state.root].id,
          name: state.items[state.root].content,
        });
      }

      setBreadcrumbs(entries);
    }
    return function () {
      setBreadcrumbs([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.root]);

  if (isLoading) {
    return <p>Loading...</p>;
  } else if (isError) {
    return (
      <div className="notification notification-alarm">Error fetching tree</div>
    );
  } else {
    return (
      <>
        <Toolbar urlKey={key} defaultView="list" isFullWidth={!isSidebarOpen} />
        <PulpTree isSlideContent={!!activeThreadItem}>
          {pulpTree(state.root)}
        </PulpTree>

        {activeThreadItem ? (
          <ThreadAside
            item={activeThreadItem}
            onClose={() => setActiveThreadItem(null)}
            onResetUnread={() =>
              dispatch({
                type: "EDIT_ITEM",
                payload: {
                  ...activeThreadItem,
                  comments_read: activeThreadItem.comments,
                },
              })
            }
          />
        ) : null}

        {activeWorkItem && state.items[activeWorkItem.id] ? (
          <WorkItemModal
            root={key}
            isOpen={!!activeWorkItem}
            item={activeWorkItem}
            workFlowConfig={state.workFlow}
            onClose={() => setActiveWorkItem(null)}
            onResetUnread={() =>
              dispatch({
                type: "EDIT_ITEM",
                payload: {
                  ...activeWorkItem,
                  comments_read: activeWorkItem.comments,
                },
              })
            }
            onWorkItemUpdated={(changset) =>
              _dispatch({
                type: "EDIT_ITEM",
                payload: {
                  id: activeWorkItem,
                  ...changset,
                },
              })
            }
            files={renderFiles(
              state,
              activeWorkItem.parent,
              state.items[activeWorkItem.parent].parent,
              _dispatch
            )}
          />
        ) : null}
      </>
    );
  }
}

export { renderFiles };
