import { useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';

import DateTime from '../../../../components/datetime/DateTime';
import { PulpApi } from '../../../../services/api';
import { useLightbox } from "../../../../hooks";
import { ReactComponent as Remove } from "../../../../assets/delete.svg";
import styles from "../pulp-block-file/pulp_block_file.module.scss";
import { useSessionId } from '../../../EditorSessionProvider';

export default function Clean({
  blockId, pulpId, item, onDeleteFile
}) {
  const sessionId = useSessionId();
  const { open: openLightbox } = useLightbox();

  const deleteFileMutation = useMutation({ mutationFn: PulpApi.DeleteFile, onSuccess: onDeleteFile })

  function deleteFileHandler() {
    deleteFileMutation.mutate({ pulpId, blockId, fileId: item.id, sessionId });
  }

  const preview = useMemo(() => {
    if (item?.preview_link) {
      return <img src={item.preview_link} alt={item.name} onClick={() => openLightbox(item)} />
    }

    switch (item.content_type) {
      case 'application/pdf': return <span className={styles.filesItemIcon}>PDF</span>;
      case 'application/zip': return <span className={styles.filesItemIcon}>ZIP</span>;
      case 'text/csv': return <span className={styles.filesItemIcon}>CSV</span>;
      case 'image/jpeg': return <span className={styles.filesItemIcon}>PDF</span>;
      default: return <span className={styles.filesItemIcon}>PNG</span>
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <div className={styles.filesItem}>
      <button className={styles.filesItemRemove} onClick={deleteFileHandler}><Remove /></button>
      {preview}
      <div className={styles.filesItemText}>
        <a className={styles.filesItemTitle} href={item.direct_link}>{item.name}</a>
        <span className={styles.filesItemDate} ><DateTime value={item.created_at} /></span>
      </div>
    </div>
  );
}
