import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { ApiError } from "../components";

import { AuthApi } from "../services/api";

export default function SettingsChangeEmailView() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [showOtp, setShowOtp] = useState(false);

  const changeEmailMutation = useMutation({
    mutationFn: AuthApi.ChangeEmail,
  });

  const onSubmit = (e) => {
    e.preventDefault();

    if (changeEmailMutation.isLoading) {
      return;
    }

    if (!showOtp && email) {
      changeEmailMutation.mutate({  email }, { onSuccess() {
        setShowOtp(true)
      } })
    } else if (showOtp && email && token) {
      changeEmailMutation.mutate({  email, code: token }, { onSuccess() {
        navigate('/settings')
      } })
    }
  };

  return (
    <div className="settings">
      <h1>Change email</h1>

      <form onSubmit={onSubmit} className="settingsForm">
        <div>
          <label>Email Address</label>
          <input
            type="email"
            placeholder="name@example.com"
            value={email}
            onInput={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {showOtp ? <div>
          <label>Verification code</label>
          <input
            type="text"
            minLength={8}
            placeholder="otp"
            value={token}
            onInput={(e) => setToken(e.target.value)}
            required
          />
        </div> : <div></div>}
        <div>
        {changeEmailMutation.error && <ApiError error={changeEmailMutation.error} />}
          <button
            className="button button-primary"
            type="submit"
            disabled={changeEmailMutation.isLoading}
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
}
