import { useMutation, useQuery } from "@tanstack/react-query";

import { useForm } from "react-hook-form";

import queryClient from "../../../../constants/queryClient";
import { Modal, ApiError } from "../../../../components";
import { ReactComponent as ShareIcon } from "../../../../assets/share.svg";
import { ReactComponent as Delete } from "../../../../assets/delete.svg";
import styles from "./pulp_share_modal.module.scss";
import { useAuth } from "../../../../hooks";
import { TeamApi, PulpApi } from "../../../../services/api";
import {
  teamApiQueryKeys,
  pulpApiQueryKeys,
} from "../../../../constants/queryKeys";

export default function PulpShareModal({ onClose, isOpen, id }) {
  const { user } = useAuth();

  const { register, handleSubmit, reset } = useForm();

  const membersQuery = useQuery(teamApiQueryKeys.members(id), () =>
    TeamApi.Members(id)
  );

  const rootQuery = useQuery(pulpApiQueryKeys.root(id), () => PulpApi.Root(id));

  const shareMutation = useMutation({
    mutationFn: TeamApi.ShareRoot,
    onSuccess(data) {
      reset();

      queryClient.setQueryData(teamApiQueryKeys.members(id), (old) => [
        ...old,
        data,
      ]);
    },
  });

  const updateAccessMutation = useMutation({
    mutationFn: TeamApi.UpdateAccess,
    onSuccess(data) {
      queryClient.setQueryData(teamApiQueryKeys.members(id), (old) =>
        old.map((m) => {
          if (m.id === data.id) {
            return data;
          }
          return m;
        })
      );
    },
  });

  const deleteShareMutation = useMutation({
    mutationFn: TeamApi.DeleteShare,
    onSuccess(data) {
      membersQuery.refetch();
    },
  });

  const shareRoot = (data) => {
    shareMutation.mutate({
      key: id,
      email: data.email,
      access: "editor",
    });
  };

  const updateAccess = ({ email, access }) => {
    updateAccessMutation.mutate({
      key: id,
      email,
      access,
    });
  };

  const deleteShare = (member_id) => {
    deleteShareMutation.mutate({
      key: id,
      member_id,
    });
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title={"Share root?"}
      titleIcon={<ShareIcon />}
      size={"medium"}
    >
      <div className={styles.block}>
        <h3>Email</h3>
        <form onSubmit={handleSubmit(shareRoot)} className={styles.form}>
          <input
            type="email"
            placeholder="contact@email.com"
            {...register("email", { required: true })}
          />
          <button type="submit" className="button button-primary">
            Share
          </button>
        </form>
      </div>
      { shareMutation.isError ? <ApiError error={shareMutation.error} /> : null }
      <div className={styles.block}>
        <h3>Members</h3>
        {membersQuery.isLoading
          ? "Loading..."
          : membersQuery.isError
          ? "Error fetching root members"
          : membersQuery.data.map(
              ({
                id,
                name,
                email,
                photo_link,
                can_edit,
                can_view,
                is_owner,
              }) => {
                return (
                  <div key={id} className={styles.member}>
                    <div>
                      <div className="avatar">
                        {photo_link && <img src={photo_link} alt={name} />}
                      </div>
                      <div>
                        {name}
                        <br />
                        <small>{email}</small>
                      </div>
                    </div>
                    <div>
                      {is_owner ? "Owner" : can_edit ? "Editor" : "Viewer"}
                    </div>
                    <div>
                      {rootQuery.isLoading ? (
                        "..."
                      ) : rootQuery.isError ? (
                        "Error"
                      ) : is_owner ? null : user.id === rootQuery.data.owner_id ? (
                        <button
                          onClick={() => deleteShare(id)}
                          className="button button-icon"
                        >
                          <Delete />
                        </button>
                      ) : null}
                    </div>
                  </div>
                );
              }
            )}
      </div>
      {/*<div className={styles.block}>
        <h3>Share read-only link</h3>
        <div className={styles.copy}>
          <input type="text" placeholder="contact@email.com" value={`kaban.com/${id}`} disabled ref={inputRef} />
          <button type="button" className="button button-primary-invert" onClick={copyClipboard}>Copy link</button>
        </div>
      </div>*/}
    </Modal>
  );
}
