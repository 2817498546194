import { useReducer } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Logo } from "../assets/logo-small.svg";

import { useProvideAuth } from "../hooks";
import { AuthApi } from "../services/api";

function reducer(state, action) {
  switch (action.type) {
    case 'email':
      return { ...state, email: action.email };
    case 'token':
      return { ...state, token: action.token };
    case 'name':
      return { ...state, name: action.name };
    case 'register_ok':
      return { ...state, showOtp: true, token: '' };
    case 'register_error':
    case 'login_error':
      return { ...state, showOtp: false, token: '' };
    default:
      throw new Error();
  }
}


const initialState = {
  showOtp: false,
  email: '',
  token: '',
};


export default function RegisterView() {
  const { login: loginUser } = useProvideAuth();

  const navigate = useNavigate();

  const [ state, dispatch ] = useReducer(reducer, initialState);

  const loginMutation = useMutation({
    mutationFn: AuthApi.Login,
    onSuccess: (data) => {
      loginUser(data.access_token)
      navigate("/");
    },
    onError: (data) => {
      dispatch({ type: 'login_error' });
    },
  });

  const registerMutation = useMutation({
    mutationFn: AuthApi.Register,
    onSuccess: (data) => {
      dispatch({ type: 'register_ok' });
    },
    onError: (data) => {
      dispatch({ type: 'register_error' });
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();

    if (state.showOtp) {
      loginMutation.mutate({ email: state.email, password: state.token });
    } else {
      registerMutation.mutate({ email: state.email, name: state.name });
    }
  };

  return (
    <div className="login-page">
      <div className="logo"><Logo /></div>
      <form onSubmit={onSubmit} className="form">
        <h2>Sign Up</h2>
        <p>Specify your email address</p>

        { loginMutation.error && <div className="alert">Cannot login user</div> }
        { registerMutation.error && <div className="alert">Cannot register user</div> }

        <input
          type="email"
          className="input-user"
          id="floatingEmail"
          placeholder="name@example.com"
          value={state.email}
          onInput={(e) => dispatch({ type: 'email', email: e.target.value })}
          required
        />

        <input
          type="text"
          className="input-user"
          id="floatingName"
          placeholder="John Doe"
          value={state.name}
          onInput={(e) => dispatch({ type: 'name', name: e.target.value })}
          required
        />

        {state.showOtp && (
          <>
            <p>Check your mailbox for the one-time password</p>
            <input
              type="text"
              minLength={8}
              maxLength={8}
              className="input-password"
              id="floatingOtp"
              placeholder="otp"
              value={state.token}
              onInput={(e) => dispatch({ type: 'token', token: e.target.value })}
              required
            />
          </>
        )}

        <button
          disabled={loginMutation.isLoading || registerMutation.isLoading}
          className="button button-primary button-fullwidth"
          type="submit"
        >
          { (loginMutation.isLoading || registerMutation.isLoading) ? 'Working...' : state.showOtp ? 'Sign in' : 'Sign up' }
        </button>

        <a href="/sign-in">Already have an account? Sign in</a>
      </form>
      <ul className="copy">
        <li>Kaban team © 2022</li>
        <li>info@kaban.app</li>
      </ul>
    </div>
  );
}
