import { useState, useEffect, useRef } from "react";

import { useOutsideClick } from "../../hooks";

import styles from "./inline_input.module.scss";

export default function InlineInput({ value, onChange }) {
  const currentField = useRef(null);
  const [localValue, setLocalValue] = useState(value);
  const [isEdit, setIsEdit] = useState(false);

  useOutsideClick(currentField, () => {
    currentField.current.blur();
  });

  useEffect(() => {
    if (isEdit) {
      currentField.current.select();
      currentField.current.style.height =
        currentField.current.scrollHeight + "px";
    }
  }, [isEdit]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }
  };

  const grow = (e) => {
    if (e.target.scrollHeight > parseInt(e.target.style.height)) {
        e.target.style.height = e.target.scrollHeight + "px";
    }
  };

  const handleOnChange = (e) => {
    const val = e.target.value.trimStart();
    setLocalValue(val)
  };

  const handleBlur = (e) => {
    if (localValue === '') {
        setLocalValue(value)
    } else {
        onChange(localValue)
    }
    setIsEdit(false)
  }

  return (
    <div className="w-100">
      {isEdit ? (
        <textarea
          onInput={grow}
          type="text"
          value={localValue}
          onKeyDown={handleKeyDown}
          onChange={handleOnChange}
          onBlur={handleBlur}
          ref={currentField}
          rows="1"
          className={styles.textarea}
        />
      ) : (
        <div className={styles.div} onClick={() => setIsEdit(true)}>
          {localValue}
        </div>
      )}
    </div>
  );
}
