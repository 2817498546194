import { $host } from "./host";

export class WorkItemsApi {
  static async PartialUpdate({id, changeset, sessionId}) {
    const { data } = await $host.patch(`/api/v1/work-items/${id}/`, changeset, { params: { sessionId } });
    return data;
  }

  static async Changelog(id) {
    const { data } = await $host.get(`/api/v1/work-items/${id}/changelog/`);
    return data;
  }

  static async DetailsSubtree({ id, sessionId }) {
    const { data } = await $host.get(`/api/v1/work-items/${id}/`, { params: { sessionId } });
    return data;
  }
}
