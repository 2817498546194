import styles from "./pulp_block_file.module.scss";
import { ReactComponent as File } from "../../../../assets/file.svg";

export default function PulpBlockFile({ onAddFile, children }) {
  return (
    <div className={styles.files}>
      {children}
      <button className={styles.filesButton} onClick={onAddFile}><File /></button>
    </div>
  );
}
