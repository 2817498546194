import { useMemo, useState } from "react";

import TagSelectorMenu from "../tag-selector-menu/TagSelectorMenu";
import getOffset from "../../../../utils/getOffset";

import styles from "../pulp-branch/pulp_branch.module.scss";
import { ReactComponent as IconComments } from "../../../../assets/comments.svg";
import { ReactComponent as IconDots } from "../../../../assets/dots.svg";
import { ReactComponent as IconDelete } from "../../../../assets/delete.svg";
import CommentsBadge from "../comments-badge/CommentsBadge";

import { tagList as pTagList } from "../pulp-block-p/PulpBlockP";

export default function PulpBlock({ onCommand, item, children }) {
  const [tagSelectorPosition, setTagSelectorPosition] = useState(null);

  const tagList = useMemo(() => {
    let blockTagList = [
      {
        tag: "delete",
        label: "Delete",
        //commands: ['command', 'D'],
        icon: <IconDelete />,
      },
    ];

    if (item.type !== "work-item") {
      blockTagList = [
        {
          tag: "show-thread",
          label: "Comment",
          //commands: ['Shift', 'command', 'M'],
          icon: <IconComments />,
        },
        ...blockTagList,
      ];
    }

    if (item.tag === "p") {
      return pTagList.concat(blockTagList);
    }

    return blockTagList;
  }, [item.type, item.tag]);

  const handleSelected = (tag) => {
    onCommand(tag);
  };

  const handleDotsClick = (e) => {
    setTagSelectorPosition(getOffset(e.target));
  };

  return (
    <div className={styles.branch}>
      {tagSelectorPosition && (
        <TagSelectorMenu
          position={tagSelectorPosition}
          close={() => setTagSelectorPosition(null)}
          onSelected={handleSelected}
        >
          {tagList}
        </TagSelectorMenu>
      )}
      <button className={styles.buttonDots} onClick={handleDotsClick}>
        <IconDots />
      </button>
      <CommentsBadge
        onClick={() => onCommand({ tag: "show-thread" })}
        comments={item.comments}
        comments_read={item.comments_read}
      />
      {children}
    </div>
  );
}
