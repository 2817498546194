import { useReducer } from "react";
import { Outlet } from "react-router-dom";
import { useState } from "react";
import layoutReducer, { initialValue } from "./reducer";

import { ProvideAvatarFeedback } from "../components";

import { PulpBreadcrumbsContext } from "../contexts";

import PulpSidebar from "./PulpSidebar";
import Navbar from "./Navbar";

export default function PulpLayout() {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [{ isSidebarOpen }, layoutDispatch] = useReducer(
    layoutReducer,
    initialValue
  );

  const handleOpenSidebar = () =>
    layoutDispatch({ type: "SET_IS_SIDEBAR_OPEN", payload: !isSidebarOpen });

  return (
    <div className={isSidebarOpen ? "container" : "container-full"}>
      <ProvideAvatarFeedback>
        <Navbar
          breadcrumbs={breadcrumbs}
          handleOpenSidebar={handleOpenSidebar}
          isSidebarOpen={isSidebarOpen}
        />
        <PulpSidebar isSidebarOpen={isSidebarOpen} />
        <div className="content">
          <PulpBreadcrumbsContext.Provider value={{ setBreadcrumbs }}>
            <Outlet context={{ isSidebarOpen }} />
          </PulpBreadcrumbsContext.Provider>
        </div>
      </ProvideAvatarFeedback>
    </div>
  );
}
