import { useContext, useMemo, useCallback } from 'react';
import { ModalContext } from '../contexts';

const generateModalKey = (() => {
  let count = 0;
  return () => `${++count}`;
})();


export default function useModal(component, data, onClose) {
  const key = useMemo(generateModalKey, []);

  const context = useContext(ModalContext);

  const showModal = useCallback(
    modalData => context.showModal(key, component, modalData instanceof Event ? data : { ...data, ...modalData }, onClose),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, context.showModal]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hideModal = useCallback((result) => context.hideModal(key), [context.hideModal, key]);

  return [showModal, hideModal];
}
