import { Link } from "react-router-dom";
import { useAuth } from "../hooks";
import { limitString } from "../utils/limitString";
import { Avatar } from "../components";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as Arrow } from "../assets/chevron.svg";
import { ReactComponent as HelpIcon } from "../assets/help.svg";
import { ReactComponent as LogOut } from "../assets/logout.svg";
import { ReactComponent as SettingsIcon } from "../assets/settings.svg";

export default function Navbar({ breadcrumbs, isSidebarOpen, handleOpenSidebar }) {
  const { user, isAuthenticated } = useAuth();

  return (
    <header className="nav-bar">
      <div className={isSidebarOpen ? "logo-box" : "logo-box logo-boxCollapse"}>
        <Link to="/" className="logo">
          <Logo />
        </Link>
        <button className={isSidebarOpen ? "expandedButton" : "collapseButton"} onClick={handleOpenSidebar}>
          <Arrow />
        </button>
      </div>
      <div className="navigation">
        {breadcrumbs && (
          <nav aria-label="breadcrumb">
            <ul className="breadcrumb">
              {breadcrumbs.map((b) => (
                <li className="breadcrumb-item" key={b.id}>
                  <a href={"#" + b.id} title={b.name}>{limitString(b.name)}</a>
                </li>
              ))}
            </ul>
          </nav>
        )}
        {isAuthenticated && (
          <ul className="auth-nav">
            <li>
              <div className="user">
                <Avatar error={false} loading={false} photo={user?.photo_link} title={user?.name} />
                <div>
                  <div className="name">{user?.name}</div>
                </div>
              </div>
            </li>
            <li>
              <Link to="/settings">
                <SettingsIcon />
              </Link>
            </li>
            <li>
              <a href="https://help.kaban.app" target="_blank" rel="noreferrer">
                <HelpIcon />
              </a>
            </li>
            <li>
              <Link to="/sign-out">
                <LogOut />
              </Link>
            </li>
            <li>
              <a href="https://kaban.nolt.io" className="nolt-feedback-button" target="_blank" rel="noreferrer">
                Feedback
              </a>
            </li>
          </ul>
        )}
      </div>
    </header>
  );
}
