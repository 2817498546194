
import { DateTime } from '../../../../components';
import alarmOptions from "../../../../constants/alarmOptions";


function formatAlarmString(item) {
  if (!item.alert_relative_value) return;
  if (item.alert_relative_value in alarmOptions) {
    return `Alarm ${alarmOptions[item.alert_relative_value].name}`;
  }
}

export default function Clean({ item }) {

  return (
    <div className='reminder'>
      <span className='reminder-title'>{item.content}</span>
      <small> - <DateTime value={item.date} /> {formatAlarmString(item)}</small>
    </div>
  );
}
