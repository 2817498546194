import { Navigate, Outlet } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";

import { useProvideAuth } from "../hooks";
import {
  ProvideAuth,
  ModalProvider,
  LightboxContextProvider,
} from "../components";

export default function ProtectedRoute() {
  const { isAuthenticated } = useProvideAuth();

  if (isAuthenticated) {
    if (isMobile || isTablet) {
      return <p>This content is available only on desktop</p>
    }
    return (
      <ProvideAuth>
        <LightboxContextProvider>
          <ModalProvider>
            <Outlet />
          </ModalProvider>
        </LightboxContextProvider>
      </ProvideAuth>
    );
  } else {
    return <Navigate to="/sign-in" />;
  }
}
