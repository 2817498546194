import { useRef, useEffect, useState } from "react";

import ContentEditable from "../../../../components/wrapped-content-editable";

import TagSelectorMenu from "../tag-selector-menu/TagSelectorMenu";
import getOffset from "../../../../utils/getOffset";
import { ReactComponent as IconFile } from "../../../../assets/file.svg";
import { ReactComponent as IconWork } from "../../../../assets/work.svg";
import { ReactComponent as IconReminder } from "../../../../assets/clock2.svg";

import styles from "./pulp_block_p.module.scss";

const tagList = [
  {
    title: "CONVERT",
    items: [
      {
        tag: "file",
        label: "Convert to file",
        //commands: ['command', 'F'],
        icon: <IconFile />,
      },
      {
        tag: "work-item",
        label: "Convert to work item",
        //commands: ['command', 'I'],
        icon: <IconWork />,
      },
      {
        tag: "reminder",
        label: "Convert to reminder",
        //commands: ['command', 'R'],
        icon: <IconReminder />,
      },
    ],
  },
  {
    items: [
      {
        tag: 'outdent-block',
        label: 'Outdent as branch',
        commands: ['Shift', 'Tab'],
      }
    ]
  }
];

export default function PulpBlockP({
  item,
  focus,
  onEdit,
  onAddNewBlock,
  onDeleteBlock,
  onCommand,
  onFocusLost
}) {
  const nodeRef = useRef();

  const [tagSelectorPosition, setTagSelectorPosition] = useState(null);

  useEffect(() => {
    if (focus && focus.cursorPosition != null) {
      const node = nodeRef.current;
      var range = document.createRange();
      var sel = window.getSelection();
      range.setStart(focus.anchorNode || node, focus.anchorNode ? focus.cursorPosition : 0);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      node.focus();
    }
  }, [focus]);

  const handleChanges = (e) => {
    if (e.target.value === "/") {
      setTagSelectorPosition(getOffset(nodeRef.current));
      return;
    }

    onEdit({
      content: e.target.value,
      cursorPosition: window.getSelection().anchorOffset,
      anchorNode: window.getSelection().anchorNode,
    });
  };

  const handleSelected = (tag) => {
    onCommand(tag);
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "Enter":
        if (e.shiftKey) {
          e.preventDefault();
          onAddNewBlock(item.tag);
        }
        break;
        case "Tab":
          e.preventDefault();
          if (e.shiftKey) {
            onCommand({ tag: 'outdent-block' });
          }
          break;
      case "Backspace":
        if (e.target.textContent === "" && item.content.replace('<br>', '') === "") {
          onDeleteBlock();
        }
        break;
      default:
        return null;
    }
  };

  const handleOnBlur = (e) => {
    if (e.target.innerText === "" && !tagSelectorPosition) {
      //onDeleteBlock();
    } else {
      onFocusLost();
    }
  };

  return (
    <>
      {tagSelectorPosition && (
        <TagSelectorMenu
          position={tagSelectorPosition}
          close={() => setTagSelectorPosition(null)}
          onSelected={handleSelected}
        >
          {tagList}
        </TagSelectorMenu>
      )}
      <ContentEditable
        className={styles.block}
        innerRef={nodeRef}
        html={item.content}
        onChange={handleChanges}
        onKeyDown={handleKeyDown}
        onBlur={handleOnBlur}
      ></ContentEditable>
      {!item.content ? (
        <div className={styles.placeholder}>Press / to show commands menu</div>
      ) : null}
    </>
  );
}

export { tagList };
