import {
  PulpLayout,
  AnonPulpLayout,
  AuthLayout,
  ProtectedRoute,
  SettingsLayout,
} from "../layouts";
import {
  PulpView,
  ReadOnlyPulpView,
  HomeView,
  LoginView,
  RegisterView,
  LogoutView,
  SettingsView,
  RootSettingsView,
  BoardView,
  SettingsChangeEmailView,
  EditorSessionProvider,
  WorkItemView,
  ErrorBoundary,
} from "../views";

export const routes = [
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        element: <PulpLayout />,
        children: [
          {
            index: true,
            element: <HomeView />,
          },
        ],
      },
      {
        element: <PulpLayout />,
        children: [
          {
            path: "/p/:key",
            errorElement: <ErrorBoundary />,
            element: (
              <EditorSessionProvider>
                <PulpView />
              </EditorSessionProvider>
            ),
          },
          {
            path: "/b/:key",
            errorElement: <ErrorBoundary />,
            element: (
              <EditorSessionProvider>
                <BoardView />
              </EditorSessionProvider>
            ),
          },
        ],
      },
      {
        path: "/w/:rootId/:workItemId",
        element: (
          <EditorSessionProvider>
            <WorkItemView />
          </EditorSessionProvider>
        ),
      },
      {
        path: "/settings",
        element: <SettingsLayout />,
        children: [
          {
            index: true,
            element: <SettingsView />,
          },
          {
            path: "p/:key",
            element: <RootSettingsView />,
          },
          {
            path: "change-email",
            element: <SettingsChangeEmailView />,
          },
        ],
      },
    ],
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/sign-in",
        element: <LoginView />,
      },
      {
        path: "/sign-up",
        element: <RegisterView />,
      },
      {
        path: "/sign-out",
        element: <LogoutView />,
      },
    ],
  },
  {
    path: "/p/:key/public",
    element: <AnonPulpLayout />,
    children: [
      {
        index: true,
        element: (
          <EditorSessionProvider>
            <ReadOnlyPulpView />
          </EditorSessionProvider>
        ),
      },
    ],
  }
].filter((item) => !!item);
